import { Component, OnInit, Inject } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

@Component({
  selector: "app-errormessage",
  templateUrl: "./errormessage.component.html",
  styleUrls: ["./errormessage.component.scss"],
})
export class ErrormessageComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<ErrormessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
