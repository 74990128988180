<div class="container" *ngIf="!loading">
  <h1 class="medical">
    {{ "Medical History" | translate }}
    <div class="edit-update">
      <button
        type="button"
        class="edit-button"
        *ngIf="showEditButton"
        (click)="enableEdit()"
      >
        <img
          class="icon"
          style="vertical-align: middle; margin-right: 8px"
          src="assets/images/editi.svg"
        />
        <span style="vertical-align: middle">{{ "Edit" | translate }}</span>
      </button>

      <button
        type="button"
        class="update-button"
        *ngIf="medicaldata && !showEditButton"
        (click)="editMedicalDetails()"
      >
        <img
          class="icon"
          style="vertical-align: middle; margin-right: 8px"
          src="assets/images/save.svg"
        />
        <span style="vertical-align: middle">{{ "Update" | translate }}</span>
      </button>

      <mat-icon
        *ngIf="showTooltipInfo"
        class="info"
        fontSet="material-icons-outlined"
        matTooltip="{{
          'Until 12:00 am you can edit this medical history' | translate
        }}."
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'message-tooltip'"
        >info_outlined</mat-icon
      >
    </div>
  </h1>

  <div>
    <form
      [formGroup]="medicalHistoryForm"
      (ngSubmit)="editMedicalDetails()"
      [ngClass]="!medicaldata ? 'chip-disabled' : ''"
    >
      <div
        class="flex-container"
        fxLayout="row"
        fxLayoutWrap
        fxLayoutGap="50px"
      >
        <div fxLayout="column" fxFlex="50">
          <mat-card
            style="
              width: 100%;
              height: 100%;
              background: #ffffff;
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
              border-radius: 6px;
            "
          >
            <h5 class="title" style="margin-top: 3%">
              {{ "Past Medical History" | translate }}
            </h5>
            <br />
            <div>
              <mat-form-field class="my-form-field" style="width: 100%">
                <mat-label>{{ "Childhood Illnesses" | translate }}</mat-label>
                <mat-select formControlName="childillness" multiple>
                  <mat-option *ngFor="let ill of illness" [value]="ill">{{
                    ill.illness[currentLanguage]
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
                *ngIf="showOtherChildillness"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{ 'Other Childillness' | translate }}"
                  formControlName="otherChildillness"
                />
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
              >
                <mat-label>{{ "Medical Conditions" | translate }}</mat-label>
                <mat-select formControlName="medicalCondition" multiple>
                  <mat-option
                    *ngFor="let medical of diseaseCondition"
                    [value]="medical"
                  >
                    {{ medical.disease[currentLanguage] }}
                  </mat-option>
                  <!-- <mat-option *ngIf="diseaseCondition.length" [value]="NA">NA</mat-option> -->
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
                *ngIf="showOtherMedicalCondition"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{ 'Other Medical Condition' | translate }}"
                  formControlName="otherMedicalCondition"
                />
              </mat-form-field>

              <!-- surgeries -->
              <div
                formArrayName="surgery"
                *ngFor="
                  let a of medicalHistoryForm['controls'].surgery['controls'];
                  let i = index
                "
              >
                <div [formGroupName]="i">
                  <mat-form-field
                    class="example-full-width my-form-field"
                    style="width: 50%"
                  >
                    <input
                      type="text"
                      matInput
                      placeholder="{{ 'Surgery' | translate }} - {{ i + 1 }}"
                      formControlName="surgery"
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="example-full-width my-form-field"
                    style="width: 30%"
                  >
                    <mat-label>{{ "Date" | translate }}</mat-label>
                    <input
                      matInput
                      [max]="maxDate"
                      [matDatepicker]="picker"
                      formControlName="date"
                      readonly
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="primary"
                    [attr.disabled]="!medicaldata"
                    class="circle"
                    *ngIf="
                      medicalHistoryForm.get('surgery')['controls'].length -
                        1 ==
                        i && medicaldata
                    "
                    (click)="addItem()"
                  >
                    <!-- <mat-icon *ngIf="medicaldata">add_circle</mat-icon> -->
                    <img
                      *ngIf="medicaldata"
                      src="../../../assets/images/add.svg"
                    />
                  </button>

                  <button
                    mat-icon-button
                    color="primary"
                    class="circle"
                    [class.disabled]="medicaldata"
                    *ngIf="
                      medicalHistoryForm.get('surgery')['controls'].length -
                        1 !=
                        i && medicaldata
                    "
                    (click)="removeInput(i)"
                  >
                    <img
                      *ngIf="medicaldata"
                      src="../../../assets/images/delete.svg"
                    />

                    <!-- <mat-icon *ngIf="medicaldata">delete_forever</mat-icon> -->
                  </button>
                </div>
              </div>

              <!-- allergies-->
              <mat-form-field
                class="example-chip-list my-form-field"
                style="width: 100%"
              >
                <mat-chip-list #chipList aria-label="Tags selection">
                  <mat-chip
                    *ngFor="
                      let tags of medicalHistoryForm.get('allergies')[
                        'controls'
                      ];
                      let i = index
                    "
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(i)"
                  >
                    {{ tags.value }}
                    <mat-icon matChipRemove *ngIf="removable"> close </mat-icon>
                  </mat-chip>
                  <input
                    matInput
                    [matChipInputFor]="chipList"
                    placeholder="{{ 'Allergies' | translate }}"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)"
                    [disabled]="!medicaldata"
                  />
                </mat-chip-list>
              </mat-form-field>
              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
              >
                <mat-label>{{ "Height" | translate }} (m)</mat-label>
                <input
                  matInput
                  placeholder="Metros"
                  formControlName="height"
                  type="text"
                  style="width: 100%"
                />
              </mat-form-field>
              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
              >
                <mat-label>{{ "Weight" | translate }} (kg)</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Kilogramos"
                  formControlName="weight"
                />
              </mat-form-field>
            </div>
          </mat-card>
          <br />
          <mat-card
            style="
              width: 100%;
              height: 100%;
              background: #ffffff;
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
              border-radius: 6px;
            "
          >
            <div>
              <h5 class="title" style="margin-top: 3%">
                {{ "Lifestyle information" | translate }}
              </h5>
            </div>

            <div>
              <div style="display: inline-flex; width: 100%">
                <span class="heading" style="width: 200px">
                  {{ "Smoking Habit" | translate }}
                </span>
                <mat-slide-toggle
                  [color]="'primary'"
                  style="text-align: center; position: relative; bottom: 5px"
                  formControlName="smoking"
                >
                </mat-slide-toggle>
              </div>
              <br /><br />
              <div style="display: inline-flex; width: 100%">
                <span class="heading" style="width: 200px">
                  {{ "Drinking Habit" | translate }}
                </span>
                <mat-slide-toggle
                  [color]="'primary'"
                  style="text-align: center; position: relative; bottom: 5px"
                  formControlName="drinking"
                >
                </mat-slide-toggle>
              </div>
            </div>
          </mat-card>
        </div>

        <div fxLayout="column" fxFlex="43">
          <mat-card
            style="
              width: 100%;
              height: 100%;
              background: #ffffff;
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
              border-radius: 6px;
            "
          >
            <div>
              <h5 class="title" style="margin-top: 3%">
                {{ "Family history" | translate }}
              </h5>
              <mat-form-field class="my-form-field" style="width: 100%">
                <mat-label>{{ "Father" | translate }}</mat-label>
                <mat-select formControlName="father" multiple>
                  <mat-option
                    *ngFor="let medical of diseaseCondition"
                    [value]="medical"
                    >{{ medical.disease[currentLanguage] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
                *ngIf="showOtherFatherIllness"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{
                    'Other Medical History of Father' | translate
                  }}"
                  formControlName="otherFatherMedicalCondition"
                />
              </mat-form-field>

              <mat-form-field class="my-form-field" style="width: 100%">
                <mat-label>{{ "Mother" | translate }}</mat-label>
                <mat-select formControlName="mother" multiple>
                  <mat-option
                    *ngFor="let medical of diseaseCondition"
                    [value]="medical"
                    >{{ medical.disease[currentLanguage] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
                *ngIf="showOtherMotherIllness"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{
                    'Other Medical History of Mother' | translate
                  }}"
                  formControlName="otherMotherMedicalCondition"
                />
              </mat-form-field>

              <mat-form-field class="my-form-field" style="width: 100%">
                <mat-label>{{ "Brother" | translate }}</mat-label>
                <mat-select formControlName="brother" multiple>
                  <mat-option
                    *ngFor="let medical of diseaseCondition"
                    [value]="medical"
                    >{{ medical.disease[currentLanguage] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
                *ngIf="showOtherBrotherIllness"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{
                    'Other Medical History of Brother' | translate
                  }}"
                  formControlName="otherBrotherMedicalCondition"
                />
              </mat-form-field>

              <mat-form-field class="my-form-field" style="width: 100%">
                <mat-label>{{ "Sister" | translate }}</mat-label>
                <mat-select formControlName="sister" multiple>
                  <mat-option
                    *ngFor="let medical of diseaseCondition"
                    [value]="medical"
                    >{{ medical.disease[currentLanguage] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
                *ngIf="showOtherSisterIllness"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{
                    'Other Medical History of Sister' | translate
                  }}"
                  formControlName="otherSisterMedicalCondition"
                />
              </mat-form-field>

              <mat-form-field class="my-form-field" style="width: 100%">
                <mat-label>{{ "Son" | translate }}</mat-label>
                <mat-select formControlName="son" multiple>
                  <mat-option
                    *ngFor="let medical of diseaseCondition"
                    [value]="medical"
                    >{{ medical.disease[currentLanguage] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
                *ngIf="showOtherSonIllness"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{ 'Other Medical History of Son' | translate }}"
                  formControlName="otherSonMedicalCondition"
                />
              </mat-form-field>

              <mat-form-field class="my-form-field" style="width: 100%">
                <mat-label>{{ "Daughter" | translate }}</mat-label>
                <mat-select formControlName="daughter" multiple>
                  <mat-option
                    *ngFor="let medical of diseaseCondition"
                    [value]="medical"
                    >{{ medical.disease[currentLanguage] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
                *ngIf="showOtherDaughterIllness"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{
                    'Other Medical History of Daughter' | translate
                  }}"
                  formControlName="otherDaughterMedicalCondition"
                />
              </mat-form-field>
            </div>
          </mat-card>
        </div>
      </div>
    </form>
    <!-- <h5>Field Values</h5>
  <pre style="font-size: 15px;">{{ medicalHistoryForm.value | json }}</pre> -->
  </div>
</div>
