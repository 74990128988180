import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { QbService } from "../qb.service";
import { Subscription } from "rxjs";
import { VideoService } from "../video/video.service";
import { CredentialsService } from "src/app/core/credentials.service";
import { ModalService } from "../modal.service";
import { MatDialog } from "@angular/material/dialog";
import { CallfeebackComponent } from "../callfeeback/callfeeback.component";
import { environment } from "src/environments/environment";
declare var QB: any;

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild("msgContainer") private myScrollContainer: ElementRef;
  @Input() userData: any;
  message: String;
  chatData = [];
  messageObj: any;
  session: any;
  senderId: string;
  time: number = 0;
  interval;
  dialogId;
  limit = 50;
  skip = 0;
  qbSessionSubscription: Subscription;
  disconnectSubscription: Subscription;
  reconnectSubscription: Subscription;
  messageSubscription: Subscription;
  notificationSubscription: Subscription;
  qbChatUserId: string;
  fileUrl: string;
  profilePic: string;

  constructor(
    private qbService: QbService,
    private videoService: VideoService,
    private credentialService: CredentialsService,
    private modalService: ModalService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fileUrl = this.credentialService.credentials.fileUrl + "/";
    this.profilePic = this.userData.patient.profilePic;
    if (this.userData.patient.parent) {
      this.qbChatUserId = this.userData.patient.parent.quickbloxId;
    } else {
      this.qbChatUserId = this.userData.patient.quickbloxId;
    }
    this.chatDialog(this.qbChatUserId);
    this.startTimer();

    this.modalService.callStatus({
      callStarted: true,
    });

    this.senderId = this.credentialService.credentials.userDetails.quickbloxId;
    this.notificationSubscription = this.qbService._notificationObj.subscribe(
      (data) => {
        if (data.from.toString() === this.qbChatUserId) {
          this.chatData.push({
            recipient_id: data.from,
            message: data.body,
            date_sent: data.date_sent,
          });
          this.skip = this.skip + 1;
          this.scrollToBottom();
        }
        this.unReadMsgCount();
      }
    );
    this.configureSettings();
  }

  disConnect() {
    this.modalService.disconnectCallSubject.next({
      callStatus: "disconnected",
    });
    this.pauseTimer();
    this.callFeedbackPopup();
  }

  callFeedbackPopup() {
    const dialogRef = this.dialog.open(CallfeebackComponent, {
      width: "418px",
      disableClose: true,
      autoFocus: false,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getPreviousHistory() {
    if (this.myScrollContainer.nativeElement.scrollTop == 0) {
      this.skip = this.chatData.length;
      this.retriveHistory(this.dialogId);
    }
  }

  /**
   * Retrive Chat History
   *
   */
  retriveHistory(dialogId) {
    var params = {
      chat_dialog_id: dialogId,
      sort_desc: "date_sent",
      limit: this.limit,
      skip: this.skip,
    };

    QB.chat.message.list(params, (error, messages) => {
      if (error) {
        console.error("Error retrieving history...", error);
      } else {
        if (messages.items.length > 0) {
          for (var i = 0; i < messages.items.length; i++) {
            this.chatData.unshift(messages.items[i]);
          }
          if (this.skip == 0) {
            this.scrollToBottom();
          } else {
            this.scrollToNth();
          }
        }
      }
    });
  }

  scrollToNth() {
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scrollTop = document.getElementById(
        "49"
      ).offsetTop;
    }, 500);
  }

  configureSettings() {
    this.messageSubscription = this.qbService.messageObservable.subscribe(
      (data: any) => {
        this.MessagesFromUsers(data.userId, data.message);
      }
    );

    this.disconnectSubscription = this.qbService.disconnectedObservable.subscribe(
      (data: any) => {
        console.log("DISCONNECTEED");
      }
    );

    this.reconnectSubscription = this.qbService.reconnectChatObservable.subscribe(
      (data: any) => {}
    );
  }

  unReadMsgCount() {
    var params = {
      chat_dialog_ids: [this.dialogId],
    };

    QB.chat.message.unreadCount(params, (error, result) => {
      if (error) {
        console.error("Error unreading msg count...", error);
      } else {
      }
    });
  }

  /**
   * On message send
   *
   * @param userId: To UserId
   * @param message: composed message
   */
  onEnter(userId, message) {
    console.log("ON ENTER");
    QB.chat.send(parseInt(userId), {
      type: "chat",
      body: message,
      extension: { save_to_history: 1 },
    });
    this.chatData.push({
      message: message,
      sender_id: this.credentialService.credentials.userDetails.quickbloxId,
      date_sent: new Date().getTime() / 1000,
    });
    this.sendPushNotification(message);
    this.skip = this.skip + 1;
    this.message = "";
    this.scrollToBottom();
  }

  // Message from opponent
  MessagesFromUsers = (userId, msg) => {
    this.messageObj = {
      from: userId,
      body: msg.body,
      date_sent: msg.extension.date_sent,
    };
    this.qbService._notificationObj.emit(this.messageObj);
  };

  chatDialog(callerId) {
    var params = {
      type: 3,
      occupants_ids: [callerId],
    };

    QB.chat.dialog.create(params, (error, conversation) => {
      if (error) {
        console.error("Error creating chat dialog...", error);
      } else {
        this.dialogId = conversation._id;
        this.retriveHistory(this.dialogId);
      }
    });
  }

  msgReadStatus() {
    var params = {
      messageId: "557f1f22bcf86cd784439022",
      userId: 21,
      dialogId: "5356c64ab35c12bd3b108a41",
    };

    QB.chat.sendReadStatus(params);
  }

  scrollToBottom() {
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }, 500);
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
    }, 1000);
  }

  transform(value: number, args?: any) {
    var h = Math.floor(value / 3600);
    var m = Math.floor((value % 3600) / 60);
    var s = Math.floor((value % 3600) % 60);

    return (
      this.str_pad_left(h, "0", 2) +
      ":" +
      this.str_pad_left(m, "0", 2) +
      ":" +
      this.str_pad_left(s, "0", 2)
    );
  }

  str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  sendPushNotification(message) {
    var payload = JSON.stringify({
      message: message,
    });
    var pushParameters = {
      notification_type: "push",
      user: { ids: [parseInt(this.qbChatUserId)] }, // recipients.
      environment: environment.qbEnvirnoment, // environment, can be 'production'.
      message: QB.pushnotifications.base64Encode(payload),
    };

    QB.pushnotifications.events.create(pushParameters, (error, result) => {
      if (error) {
        console.error("Error creating QB notifications...", error);
      } else {
        // success
      }
    });
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    this.reconnectSubscription.unsubscribe();
    this.disconnectSubscription.unsubscribe();
    this.notificationSubscription.unsubscribe();
  }
}
