import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  Renderer2,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { CredentialsService } from "src/app/core/credentials.service";
import { CommonService } from "../common.services";
import { SharedService } from "src/app/shared/shared.service";
import { RegistrationService } from "src/app/registration/registration.service";
import moment from "moment";
import { Observable, zip } from "rxjs";
import { SignatureFieldComponent } from "src/app/shared/signature-field/signature-page.component";
import { concat } from "rxjs";
import { ErrorStateMatcher } from "@angular/material/core";

@Component({
  selector: "app-editprofile",
  templateUrl: "./editprofile.component.html",
  styleUrls: ["./editprofile.component.scss"],
})
export class EditprofileComponent implements OnInit {
  doctorId;
  specialityList = [];
  subSpecialityList = [];
  editProfileForm: FormGroup;
  showSpeciality: boolean = true;
  showsubSpeciality: boolean = true;
  showOtherSpeciality: boolean = false;
  showOtherSubSpeciality: boolean = false;
  savebuttonshow: boolean = false;
  editInformation;
  DOB;
  Gender;
  profilePicUrl = "";
  profilePicPath: any;
  picInfo = true;
  profileProviders: any[] = [];
  picError: boolean = false;
  profilePicChanged = false;
  profilePicBlob: any;
  @ViewChild("profilePic", { read: true, static: false })
  profilePic: ElementRef;
  documentPath = "";
  enableEditbtn: boolean = false;
  currentLanguage: string;
  maxDate: any;
  nameRegex = /^([^0-9]*)$/;
  hasFirmPicture = true;

  @ViewChild("form") formElement: ElementRef;

  @ViewChild(SignatureFieldComponent) public sig1: SignatureFieldComponent;
  @ViewChild("sigContainer1") public sigContainer1: ElementRef;

  signatureError = {
    message: "La Firma es requerida",
    status: false,
  };

  public signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    canvasHeight: 125,
    minWidth: "0.9",
    maxWidth: "0.9",
  };

  constructor(
    private credentialService: CredentialsService,
    private fb: FormBuilder,
    private router: Router,
    private doctorService: RegistrationService,
    private commonServices: CommonService,
    private translationService: TranslateService,
    private sharedService: SharedService,
    private ref: ChangeDetectorRef
  ) {
    this.getSpecialitiesList();
    this.getSubSpecialityList();
  }

  ngOnInit() {
    this.maxDate = moment().subtract(18, "y").format("YYYY-MM-DD");
    if (this.translationService.currentLang == "en-US") {
      this.currentLanguage = "en";
    } else {
      this.currentLanguage = "es";
    }

    this.doctorId = this.credentialService.credentials.userDetails._id;
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.editProfileForm = this.fb.group({
      firstName: [
        "",
        [Validators.required, Validators.pattern(this.nameRegex)],
      ],
      lastName: ["", [Validators.required, Validators.pattern(this.nameRegex)]],
      gender: ["", Validators.required],
      dateOfBirth: ["", Validators.required],
      about: [""],
      identificationNo: ["", Validators.required],
      speciality: ["", Validators.required],
      subspeciality: [""],
      cmNO: ["", [Validators.required, Validators.pattern(/[0-9]/)]],
      mppsNo: ["", [Validators.required, Validators.pattern(/[0-9]/)]],
      email: ["", [Validators.required, Validators.pattern(emailregex)]],
      countryCode: ["", [Validators.required, Validators.maxLength(3)]],
      phoneNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16),
        ],
      ],
      otherSubSpeciality: [""],
      otherSpeciality: [""],
      signature: ["Firma", Validators.required],
    });

    this.editProfileForm.disable();
    this.showAndHideSpeciality();
    this.showAndHideSubSpeciality();
  }

  _keyPress(event: any) {
    const pattern = /[0-9\b]+/;
    console.log(event.keyCode);

    if (event.keyCode === 8) {
      return;
    }

    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  getSpecialitiesList() {
    this.doctorService.getSpecialitiesList().subscribe((res: any) => {
      this.specialityList = res.data;
    });
  }

  getSubSpecialityList() {
    this.doctorService.getSubSpecialitiesList().subscribe((res: any) => {
      this.subSpecialityList = res.data;
      this.getDoctorsInfo();
    });
  }

  showAndHideSpeciality() {
    this.editProfileForm.get("speciality").valueChanges.subscribe((val) => {
      var otherSpecial = this.editProfileForm.get("otherSpeciality");

      if (val) {
        for (var i = 0; i < val.length; i++) {
          if (val[i].isOther === true) {
            this.showOtherSpeciality = true;
            otherSpecial.setValidators([
              Validators.required,
              Validators.pattern(this.nameRegex),
            ]);
            return;
          } else {
            this.showOtherSpeciality = false;
            otherSpecial.setValidators(null);
          }
          otherSpecial.updateValueAndValidity();
        }
      }
    });
  }

  showAndHideSubSpeciality() {
    this.editProfileForm.get("subspeciality").valueChanges.subscribe((vals) => {
      var otherSubSpecial = this.editProfileForm.get("otherSubSpeciality");
      if (vals) {
        for (var j = 0; j < vals.length; j++) {
          if (vals[j].isOther === true) {
            this.showOtherSubSpeciality = true;

            otherSubSpecial.setValidators([
              Validators.required,
              Validators.pattern(this.nameRegex),
            ]);

            return;
          } else {
            this.showOtherSubSpeciality = false;
            otherSubSpecial.setValidators(null);
          }
          otherSubSpecial.updateValueAndValidity();
        }
      }
    });
  }

  showAndHideSignature() {}

  enableProfile() {
    this.editProfileForm.enable();
    this.editProfileForm.get("email").disable();
    this.savebuttonshow = true;
    this.beResponsive();
    if (this.sig1) {
      this.sig1.registerOnChange((signature) => {
        if (!signature) {
          this.editProfileForm.controls["signature"].setValue("");
          this.editProfileForm.controls["signature"].setErrors({
            required: true,
          });
        } else {
          this.editProfileForm.controls["signature"].clearValidators();
          this.editProfileForm.controls["signature"].setValue("");
        }

        this.editProfileForm.updateValueAndValidity();
      });
    }
  }

  getDoctorsInfo() {
    this.doctorService.getDoctors().subscribe(
      (res: any) => {
        this.hasFirmPicture = !!res.data.firmPic;

        if (this.hasFirmPicture) {
          this.editProfileForm.get("signature").clearValidators();
        }

        if (res.data.providers) {
          this.profileProviders = res.data.providers;
        }

        var speciality = [];
        let otherSpecial;
        let otherSubSpecial;

        for (var i = 0; i < res.data.speciality.length; i++) {
          for (var k = 0; k < this.specialityList.length; k++) {
            if (res.data.speciality[i].id == this.specialityList[k]._id) {
              speciality.push(this.specialityList[k]);
              if (this.specialityList[k].isOther == true) {
                otherSpecial = res.data.speciality[i].other;
              }
            }
          }
        }
        var subSpeciality = [];
        for (var j = 0; j < res.data.subSpeciality.length; j++) {
          for (var sk = 0; sk < this.subSpecialityList.length; sk++) {
            if (
              res.data.subSpeciality[j].id == this.subSpecialityList[sk]._id
            ) {
              subSpeciality.push(this.subSpecialityList[sk]);
              if (this.subSpecialityList[sk].isOther == true) {
                otherSubSpecial = res.data.subSpeciality[j].other;
              }
            }
          }
        }
        this.editProfileForm.patchValue({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          dateOfBirth: res.data.dateOfBirth,
          gender: res.data.gender,
          about: res.data.about,
          identificationNo: res.data.identificationNumber,
          speciality: speciality,
          subspeciality: subSpeciality,
          cmNO: res.data.cmNumber,
          mppsNo: res.data.mppsNumber,
          email: res.data.email,
          countryCode: res.data.countryCode,
          phoneNumber: res.data.phoneNumber,
          otherSpeciality: otherSpecial,
          otherSubSpeciality: otherSubSpecial,
        });
        this.documentPath = res["meta"].fileUrl;
        this.profilePicPath = res.data.profilePic;
        this.profilePicUrl = this.documentPath + "/" + this.profilePicPath;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }
  editDoctorInfo() {
    if (!this.editProfileForm.valid) {
      return;
    } else {
      this.editInformation = this.editProfileForm.value;

      let data = {
        firstName: this.editInformation.firstName,
        lastName: this.editInformation.lastName,
        mppsNumber: this.editInformation.mppsNo,
        cmNumber: this.editInformation.cmNO,
        about: this.editInformation.about,
        identificationNumber: this.editInformation.identificationNo,
        phoneNumber: this.editInformation.phoneNumber,
        countryCode: this.editInformation.countryCode,
        dateOfBirth: this.editInformation.dateOfBirth,
        gender: this.editInformation.gender,
        speciality: this.editInformation.speciality.map((a: any) => a._id),
        subSpeciality:
          this.editInformation.subspeciality &&
          this.editInformation.subspeciality.length > 0
            ? this.editInformation.subspeciality.map((b: any) => b._id)
            : [],
        otherSpeciality: this.editInformation.otherSpeciality,
        otherSubSpeciality: this.editInformation.otherSubSpeciality,
      };
      this.editProfileForm.get("signature").setValue("");

      let $editDoctorObservable;
      let form = new FormData();

      if (!this.hasFirmPicture && !this.sig1.signature) {
        this.signatureError.status = true;

        return;
      } else if (!this.hasFirmPicture && this.sig1.signature) {
        this.signatureError.status = false;
        form.append(
          "file",
          new File(
            [this.dataURItoBlob(this.sig1.signaturePad.toDataURL("png"))],
            "signature.csv"
          )
        );
        $editDoctorObservable = concat(
          this.doctorService.updateFirmPic(form),
          this.doctorService.editDoctors(data)
        );
      } else {
        $editDoctorObservable = this.doctorService.editDoctors(data);
      }

      $editDoctorObservable.subscribe(
        (res: any) => {
          this.translationService
            .get("Profile has been Updated Successfully")
            .subscribe((text: string) => {
              this.sharedService.showMessage(text, "success");
            });
          this.router.navigate(["/"]);
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
        }
      );
    }
  }

  updateProfileInfo(event) {
    let fileName = event.target.files[0].name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      //TO DO
    } else {
      this.translationService
        .get("Please upload only JPEG or PNG format")
        .subscribe((text: string) => {
          this.sharedService.showErrorMessage(text, "single");
        });
      return;
    }

    if (event.target.files[0].size > 5 * 1024 * 1024) {
      this.translationService
        .get("Size should be less than 5MB")
        .subscribe((text: string) => {
          this.sharedService.showErrorMessage(text, "single");
        });
      return;
    }

    let formData = new FormData();
    const file: File = event.target.files[0].name;
    formData.append("file", event.target.files[0]);
    this.doctorService.updateProfilePic(formData).subscribe(
      (res: any) => {
        this.documentPath = res["meta"].fileUrl;
        this.profilePicUrl = `${this.documentPath}/${res.data.profilePic}`;
        window.location.reload();
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  navigateToHome() {
    this.router.navigate(["/"]);
  }

  // set the dimensions of the signature pad canvas
  public beResponsive() {
    this.ref.detectChanges();
    this.size(this.sigContainer1, this.sig1);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    // this.sigContainer1.nativeElement.style.width = 100;
    console.log("container", container);
    if (sig) {
      sig.signaturePad["signaturePad"]._canvas.width =
        container.nativeElement.clientWidth;
    }
  }

  public setOptions() {
    this.sig1.signaturePad.set("penColor", "rgb(0, 0, 0)");
  }

  public clear() {
    this.sig1.clear();
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });
  }
  // maxDeviation is the difference that is allowed default: 50kb
  // Example: targetFileSizeKb = 500 then result will be between 450kb and 500kb
  // increase the deviation to reduce the amount of iterations.
}
