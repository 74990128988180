import {
  Component,
  ViewChild,
  OnInit,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Input,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormArray,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { DashboardService } from "../dashboard.service";
import { ModalService } from "../modal.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DataStoreServiceService } from "../data-store-service.service";
import { concat, forkJoin, merge, Observable, race, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/shared/shared.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AttachmentPopupComponent } from "../attachment-popup/attachment-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { CredentialsService } from "src/app/core/credentials.service";
import { filter, map, startWith, tap } from "rxjs/operators";

@Component({
  selector: "app-new-visit-summary",
  templateUrl: "./new-visit-summary.component.html",
  styleUrls: ["./new-visit-summary.component.scss"],
})
export class NewVisitSummaryComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild("submitBtn") submitBtn: ElementRef;
  @Input() followCallingUp: boolean;
  chiefComplaintList = [];
  Units: any = [];
  bodysystem: any = [];
  adminstrations: any = [];
  tablete: any = [];
  consultId: string;
  public currentVisitDetails: any;
  public singPatientRecord: any;
  loading: boolean = true;
  visitSummary: boolean = false;
  isTestCall: boolean = false;
  istestingCallingUp: any;
  vsSubmitSubscription: Subscription;
  followUpInfo: any;
  vscallStatusSubscription: Subscription;
  currentLanguage: string;
  isPlanValid = true;
  isDiagnosisValid = true;
  isPresentIllnessValid = true;
  closureReasons: any = [];
  isOtherClosureReason: boolean = false;
  closeAttentionForm = this.fb.group({
    closeAttention: [false, [Validators.required]],
    closureReason: [""],
    otherClosureReason: [""],
  });

  nonWhitespaceRegExp: RegExp = new RegExp("(?<=<div>)([wds]*?)(?=</div>)");

  filteredOptions: Observable<string[]>;

  form = this.fb.group({
    chiefComplaint: ["", [Validators.required]],
    otherChiefComplaint: [""],
    historyOfPresentIllness: ["", [Validators.required, this.noEmptyHTML()]],
    heartRate: [""],
    respiratoryRate: [""],
    temperature: ["", Validators.pattern(/^\d{0,3}(\.\d{0,2})?$/)],
    bloodPressureSystolic: [""],
    bloodPressureDiastolic: [""],
    paraclinics: [""],
    differentialDiagnosis: [""],
    diagnosis: ["", [Validators.required, this.noEmptyHTML()]],
    plan: ["", [Validators.required, this.noEmptyHTML()]],
    doctorNotes: [""],
    paraclinicalPrescription: this.fb.array([
      this.initParaClinicalPrescription(),
    ]),
    reviewOfSystem: this.fb.array([this.initReviewOfSystem()]),
    medicalPrescription: this.fb.array([this.initX()]),
    followUpForm: this.fb.group({
      subjective: [""],
      objective: [""],
      plan: [""],
      assesment: [""],
      closeAttentionForm: this.closeAttentionForm,
    }),
    mpFollowUpForm1: this.fb.group({
      subjective: [""],
      objective: [""],
      plan: [""],
      assesment: [""],
    }),
    mpFollowUpForm2: this.fb.group({
      subjective: [""],
      objective: [""],
      plan: [""],
      assesment: [""],
    }),
    closeAttentionForm: this.closeAttentionForm,
  });

  mpFollowUpForm = this.fb.group({
    medicalPrescription: this.fb.array([this.initX()]),
  });

  mpFollowUpForm1 = this.fb.group({
    medicalPrescription: this.fb.array([this.initX()]),
  });

  mpFollowUpForm2 = this.fb.group({
    medicalPrescription: this.fb.array([this.initX()]),
  });

  formControlPlaceholder = "";

  showOtherChiefComplaint: boolean = false;
  historyOfPresentIllness = "";

  isOriginalDoctor = true;
  isFollowUpEditEnabled = false;
  isRegularFormEnabled = false;
  reviewOfSystemSubscription$: Subscription;

  noEmptyHTML(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueWithoutHtmlTags = (control.value ?? "").replace(
        /<[^>]+>/g,
        ""
      );
      const valueWithoutTagsAndSpace = valueWithoutHtmlTags.replace(
        /&nbsp;/g,
        ""
      );

      const result =
        valueWithoutTagsAndSpace == "" ||
        new RegExp(/^\s*$/).test(valueWithoutTagsAndSpace);

      return result ? { notEmptyText: { value: control.value } } : null;
    };
  }

  setHistoryOfPresentIllness(val: any) {
    const valueWithoutHtmlTags = val.replace(/<[^>]+>/g, "");
    const valueWithoutTagsAndSpace = valueWithoutHtmlTags.replace(
      /&nbsp;/g,
      ""
    );

    if (
      valueWithoutTagsAndSpace == "" ||
      new RegExp(/^\s*$/).test(valueWithoutTagsAndSpace)
    ) {
      this.isPresentIllnessValid = false;
    } else {
      this.isPresentIllnessValid = true;
    }

    if (!(val instanceof Event))
      this.form.patchValue({ historyOfPresentIllness: val });
  }

  paraclinics = "";
  setParaclinics(val: any) {
    if (!(val instanceof Event))
      this.form.patchValue({
        paraclinics: val,
      });
  }

  differentialDiagnosis = "";
  setDifferentialDiagnosis(val: any) {
    if (!(val instanceof Event))
      this.form.patchValue({ differentialDiagnosis: val });
  }

  diagnosis = "";
  setDiagnosis(val: any) {
    const valueWithoutHtmlTags = val.replace(/<[^>]+>/g, "");
    const valueWithoutTagsAndSpace = valueWithoutHtmlTags.replace(
      /&nbsp;/g,
      ""
    );

    if (
      valueWithoutTagsAndSpace == "" ||
      new RegExp(/^\s*$/).test(valueWithoutTagsAndSpace)
    ) {
      this.isDiagnosisValid = false;
    } else {
      this.isDiagnosisValid = true;
    }
    if (!(val instanceof Event)) this.form.patchValue({ diagnosis: val });
  }

  plan = "";
  setPlan(val: any) {
    const valueWithoutHtmlTags = val.replace(/<[^>]+>/g, "");
    const valueWithoutTagsAndSpace = valueWithoutHtmlTags.replace(
      /&nbsp;/g,
      ""
    );

    if (
      valueWithoutTagsAndSpace == "" ||
      new RegExp(/^\s*$/).test(valueWithoutTagsAndSpace)
    ) {
      this.isPlanValid = false;
    } else {
      this.isPlanValid = true;
    }

    if (!(val instanceof Event)) this.form.patchValue({ plan: val });
  }

  doctorNotes = "";
  setDoctorNotes(val: any) {
    if (!(val instanceof Event)) this.form.patchValue({ doctorNotes: val });
  }

  followUpCall = {
    subjective: "",
    objective: "",
    assesment: "",
    plan: "",
  };

  haveFollowUp1 = false;
  previousFollowUp1 = {
    subjective: "",
    objective: "",
    assesment: "",
    plan: "",
  };

  haveFollowUp2 = false;
  previousFollowUp2 = {
    subjective: "",
    objective: "",
    assesment: "",
    plan: "",
  };

  updateFollowUp(forWhich: string, val: any) {
    this.form.patchValue({
      followUpForm: {
        [forWhich]: val,
      },
    });
  }

  private _dosageAndUnitSubcription = Subscription.EMPTY;

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: DataStoreServiceService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private credentialService: CredentialsService
  ) {
    this.currentVisitDetails = {
      document: [],
    };

    this.activatedRoute.queryParams.subscribe((params) => {
      this.consultId = params.id;
    });

    this.store
      .getPatientRecord()
      .pipe(filter((record) => !!record))
      .subscribe((newName) => {
        console.log(
          this.isOriginalDoctor,
          !this.singPatientRecord?.consultation?.isFollowUp,
          "PRIMERO"
        );
        this.singPatientRecord = newName;

        this.loading = false;

        if (
          this.singPatientRecord &&
          this.singPatientRecord.consultation.status === 4
        ) {
          this.visitSummary = true;

          if (
            this.singPatientRecord &&
            this.singPatientRecord.consultation.isFollowUp == true
          ) {
            this.getVisitDetails(
              this.singPatientRecord.consultation.parentConsultation
            );
          }
        } else {
          this.visitSummary = false;
        }
      });

    this.dashboardService.getConsultationDetails(this.consultId).subscribe(
      (res: any) => {
        this.currentVisitDetails = res.data;
        this.getCheifComplaintsList();
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );

    this.getUnits();
    this.getRouteofAdmininstration();
    this.getTablete();
    this.getBodySystem();
    this.getClosureReasons();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.chiefComplaintList
      .map((chiefComplaint) => chiefComplaint.chiefComplaint.es)
      .filter((option) => option.toLowerCase().includes(filterValue));
  }

  ngOnInit() {
    this.filteredOptions = this.form.controls[
      "chiefComplaint"
    ].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(this.form.get("chiefComplaint").value))
    );

    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
    } else {
      this.currentLanguage = "es";
    }

    this.vscallStatusSubscription = this.modalService.vscallStatusObservable.subscribe(
      (res) => {
        if (res.callStarted == true) {
          this.visitSummary = true;
          if (
            this.singPatientRecord &&
            this.singPatientRecord.consultation.isFollowUp == true
          ) {
            this.getVisitDetails(
              this.singPatientRecord.consultation.parentConsultation
            );
          }
        }
      },
      (err) => console.log("error", err)
    );

    this.form
      .get("paraclinicalPrescription")
      .valueChanges.subscribe((data) => this.validatePcp());
    this.validatePcp();

    this.reviewOfSystemSubscription$ = this.form
      .get("reviewOfSystem")
      .valueChanges.subscribe((data) => this.validateReviewOfSystem());

    this.validateReviewOfSystem();

    this.vsSubmitSubscription = this.modalService.vsSubmitObservable.subscribe(
      (res) => {
        this.followUpInfo = res;
        if (this.isTestCall == true) {
          let data = {
            isTestCall: this.istestingCallingUp,
            hasFollowUp: res.hasFollowUp,
            followUpDate: res.followUpDate,
          };
          this.dashboardService
            .createVisitSummary(this.consultId, data)
            .subscribe(
              (res: any) => {
                this.router.navigate([""]);
              },
              (err) => {}
            );
        } else if (this.isTestCall == false) {
          this.form.markAllAsTouched();
          this.form.markAsDirty();
          this.formSubmit();
        }
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.enableUpdateBtn();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.followCallingUp.currentValue) {
      const closeAttentionForm: FormGroup = this.form.get(
        "closeAttentionForm"
      ) as FormGroup;

      closeAttentionForm.get("closeAttention").setValue(false);
      closeAttentionForm.get("closureReason").setErrors(null);
      closeAttentionForm.get("otherClosureReason").setErrors(null);

      this.closeAttentionChange({ checked: false });
    }
  }

  getNameUpdate(event?, index?, forFollowUp?) {
    this._dosageAndUnitSubcription.unsubscribe();
    const value = event.target.value;
    let XsA = forFollowUp
      ? <FormArray>this.mpFollowUpForm["controls"].medicalPrescription
      : <FormArray>this.form["controls"].medicalPrescription;

    if (value.length > 0) {
      XsA.at(index).enable();
      XsA.at(index)
        .get("frequency")
        .setValidators([
          Validators.required,
          Validators.pattern(/^[1-9]\d{0,2}(?:\,\d{1,3})?$/),
        ]);
      XsA.at(index).get("frequency").updateValueAndValidity();
      XsA.at(index).get("frequency").enable();
      XsA.at(index).get("frequencyTime").setValidators([Validators.required]);
      XsA.at(index).get("frequencyTime").updateValueAndValidity();
      XsA.at(index).get("frequencyTime").enable();
      XsA.at(index).get("duration").setValidators([Validators.required]);
      XsA.at(index).get("duration").updateValueAndValidity();
      XsA.at(index).get("duration").enable();
      XsA.at(index).get("dosage").setValidators([Validators.required]);
      XsA.at(index).get("dosage").updateValueAndValidity();
      XsA.at(index).get("dosage").enable();
      XsA.at(index).get("durationTime").setValidators([Validators.required]);
      XsA.at(index).get("durationTime").updateValueAndValidity();
      XsA.at(index).get("durationTime").enable();

      let resetFields = false; // updateValueAndValidity fires a change event, this prevents looping through the observable.

      this._dosageAndUnitSubcription = merge(
        // XsA.at(index).get("dosage").valueChanges,
        XsA.at(index).get("unit").valueChanges
      ).subscribe((value) => {
        if (value) {
          // XsA.at(index).get("dosage").setValidators([Validators.required]);
          // XsA.at(index).get("dosage").enable({ emitEvent: false });

          XsA.at(index).get("dosage").setValidators([Validators.required]);
          XsA.at(index).get("unit").setValidators([Validators.required]);

          if (!this.singPatientRecord?.consultation?.isFollowUp) {
            XsA.at(index).get("dosage").enable({ emitEvent: false });
            XsA.at(index).get("unit").enable({ emitEvent: false });
          }

          console.log(
            this.isOriginalDoctor,
            !this.singPatientRecord?.consultation?.isFollowUp,
            "MEDIO"
          );

          resetFields = false;
        } else {
          if (!resetFields) {
            resetFields = true;
            XsA.at(index).get("unit").setValidators([]);
            // XsA.at(index).get("dosage").setValidators([]);
            XsA.at(index).get("unit").updateValueAndValidity();
            // XsA.at(index).get("dosage").updateValueAndValidity();
          }
        }
      });
    } else {
      XsA.at(index).disable();
      XsA.at(index).get("name").enable();
    }
  }

  getAlternateNameUpdate(event, index, alterIndex, forFollowUp) {
    const value = event.target.value;
    let XsA = forFollowUp
      ? <FormArray>this.mpFollowUpForm["controls"].medicalPrescription
      : <FormArray>this.form["controls"].medicalPrescription;
    let alternate = <FormArray>XsA.at(index).get("alternative");
    const currentItem = alternate.at(alterIndex);
    if (currentItem) {
      if (value.length > 0) {
        currentItem.enable();
        currentItem.get("frequency").setValidators([Validators.required]);
        currentItem.get("frequency").updateValueAndValidity();
        currentItem.get("frequency").enable();
        currentItem.get("frequencyTime").setValidators([Validators.required]);
        currentItem.get("frequencyTime").updateValueAndValidity();
        currentItem.get("frequencyTime").enable();
        currentItem.get("duration").setValidators([Validators.required]);
        currentItem.get("duration").updateValueAndValidity();
        currentItem.get("duration").enable();
        currentItem.get("durationTime").setValidators([Validators.required]);
        currentItem.get("durationTime").updateValueAndValidity();
        currentItem.get("durationTime").enable();
      } else {
        currentItem.disable();
        currentItem.get("name").enable();
      }
    }
  }

  getVisitDetails(parentId) {
    console.log("CONSULTATION DETAIL");
    this.dashboardService.getConsultationDetails(parentId).subscribe(
      (res: any) => {
        if (
          this.credentialService.credentials.userDetails._id ===
          res.data.doctor._id
        ) {
          this.isOriginalDoctor = true;
        } else {
          this.isOriginalDoctor = false;
        }

        this.mpFollowUpForm1.disable();
        if (
          res.data.followUpConsultation &&
          res.data.followUpConsultation.followUpForm
        ) {
          this.haveFollowUp1 = true;
          this.previousFollowUp1.subjective =
            res.data.followUpConsultation.followUpForm.subjective;
          this.previousFollowUp1.objective =
            res.data.followUpConsultation.followUpForm.objective;
          this.previousFollowUp1.assesment =
            res.data.followUpConsultation.followUpForm.assesment;
          this.previousFollowUp1.plan =
            res.data.followUpConsultation.followUpForm.plan;
          if (
            res.data.followUpConsultation.medicalPrescription &&
            res.data.followUpConsultation.medicalPrescription != null
          ) {
            console.log(res.data.followUpConsultation.medicalPrescription);
            for (
              var j = 0;
              j < res.data.followUpConsultation.medicalPrescription.length;
              j++
            ) {
              res.data.followUpConsultation.medicalPrescription[
                j
              ].durationTime =
                res.data.followUpConsultation.medicalPrescription[
                  j
                ].durationTime.id;
              res.data.followUpConsultation.medicalPrescription[
                j
              ].frequencyTime =
                res.data.followUpConsultation.medicalPrescription[
                  j
                ].frequencyTime.id;
              res.data.followUpConsultation.medicalPrescription[
                j
              ].routeOfAdministration = res.data.followUpConsultation
                .medicalPrescription[j].routeOfAdministration
                ? res.data.followUpConsultation.medicalPrescription[j]
                    .routeOfAdministration.id
                : "";
              res.data.followUpConsultation.medicalPrescription[j].unit = res
                .data.followUpConsultation.medicalPrescription[j].unit
                ? res.data.followUpConsultation.medicalPrescription[j].unit.id
                : "";
              for (
                var k = 0;
                k <
                res.data.followUpConsultation.medicalPrescription[j].alternative
                  .length;
                k++
              ) {
                res.data.followUpConsultation.medicalPrescription[
                  j
                ].alternative[k].durationTime =
                  res.data.followUpConsultation.medicalPrescription[
                    j
                  ].alternative[k].durationTime.id;
                res.data.followUpConsultation.medicalPrescription[
                  j
                ].alternative[k].frequencyTime =
                  res.data.followUpConsultation.medicalPrescription[
                    j
                  ].alternative[k].frequencyTime.id;
                res.data.followUpConsultation.medicalPrescription[
                  j
                ].alternative[k].routeOfAdministration = res.data
                  .followUpConsultation.medicalPrescription[j].alternative[k]
                  .routeOfAdministration
                  ? res.data.followUpConsultation.medicalPrescription[j]
                      .alternative[k].routeOfAdministration.id
                  : "";
                res.data.followUpConsultation.medicalPrescription[
                  j
                ].alternative[k].unit = res.data.followUpConsultation
                  .medicalPrescription[j].alternative[k].unit
                  ? res.data.followUpConsultation.medicalPrescription[j]
                      .alternative[k].unit.id
                  : "";
              }
            }

            this.setExistMedicalPrescription(
              res.data.followUpConsultation.medicalPrescription,
              this.mpFollowUpForm1
            );
          }
        }

        this.mpFollowUpForm2.disable();
        if (
          res.data.followUpConsultation2 &&
          res.data.followUpConsultation2.followUpForm
        ) {
          this.haveFollowUp2 = true;
          this.previousFollowUp2.subjective =
            res.data.followUpConsultation2.followUpForm.subjective;
          this.previousFollowUp2.objective =
            res.data.followUpConsultation2.followUpForm.objective;
          this.previousFollowUp2.assesment =
            res.data.followUpConsultation2.followUpForm.assesment;
          this.previousFollowUp2.plan =
            res.data.followUpConsultation2.followUpForm.plan;
          if (
            res.data.followUpConsultation2.medicalPrescription &&
            res.data.followUpConsultation2.medicalPrescription != null
          ) {
            console.log(res.data.followUpConsultation2.medicalPrescription);
            for (
              var j = 0;
              j < res.data.followUpConsultation2.medicalPrescription.length;
              j++
            ) {
              res.data.followUpConsultation2.medicalPrescription[
                j
              ].durationTime =
                res.data.followUpConsultation2.medicalPrescription[
                  j
                ].durationTime.id;
              res.data.followUpConsultation2.medicalPrescription[
                j
              ].frequencyTime =
                res.data.followUpConsultation2.medicalPrescription[
                  j
                ].frequencyTime.id;
              res.data.followUpConsultation2.medicalPrescription[
                j
              ].routeOfAdministration = res.data.followUpConsultation2
                .medicalPrescription[j].routeOfAdministration
                ? res.data.followUpConsultation2.medicalPrescription[j]
                    .routeOfAdministration.id
                : "";
              res.data.followUpConsultation2.medicalPrescription[j].unit = res
                .data.followUpConsultation2.medicalPrescription[j].unit
                ? res.data.followUpConsultation2.medicalPrescription[j].unit.id
                : "";
              for (
                var k = 0;
                k <
                res.data.followUpConsultation2.medicalPrescription[j]
                  .alternative.length;
                k++
              ) {
                res.data.followUpConsultation2.medicalPrescription[
                  j
                ].alternative[k].durationTime =
                  res.data.followUpConsultation2.medicalPrescription[
                    j
                  ].alternative[k].durationTime.id;
                res.data.followUpConsultation2.medicalPrescription[
                  j
                ].alternative[k].frequencyTime =
                  res.data.followUpConsultation2.medicalPrescription[
                    j
                  ].alternative[k].frequencyTime.id;
                res.data.followUpConsultation2.medicalPrescription[
                  j
                ].alternative[k].routeOfAdministration = res.data
                  .followUpConsultation2.medicalPrescription[j].alternative[k]
                  .routeOfAdministration
                  ? res.data.followUpConsultation2.medicalPrescription[j]
                      .alternative[k].routeOfAdministration.id
                  : "";
                res.data.followUpConsultation2.medicalPrescription[
                  j
                ].alternative[k].unit = res.data.followUpConsultation2
                  .medicalPrescription[j].alternative[k].unit
                  ? res.data.followUpConsultation2.medicalPrescription[j]
                      .alternative[k].unit.id
                  : "";
              }
            }

            this.setExistMedicalPrescription(
              res.data.followUpConsultation2.medicalPrescription,
              this.mpFollowUpForm2
            );
          }
        }

        let rosList;
        if (
          res.data.visitForm &&
          res.data.visitForm.reviewOfSystem &&
          res.data.visitForm.reviewOfSystem != null
        ) {
          for (var i = 0; i < res.data.visitForm.reviewOfSystem.length; i++) {
            res.data.visitForm.reviewOfSystem[i].bodySystem =
              res.data.visitForm.reviewOfSystem[i].id;
          }
        }

        if (
          res.data.medicalPrescription &&
          res.data.medicalPrescription != null
        ) {
          for (var j = 0; j < res.data.medicalPrescription.length; j++) {
            res.data.medicalPrescription[j].durationTime =
              res.data.medicalPrescription[j].durationTime.id;
            res.data.medicalPrescription[j].frequencyTime =
              res.data.medicalPrescription[j].frequencyTime.id;
            res.data.medicalPrescription[j].routeOfAdministration = res.data
              .medicalPrescription[j].routeOfAdministration
              ? res.data.medicalPrescription[j].routeOfAdministration.id
              : "";
            res.data.medicalPrescription[j].unit = res.data.medicalPrescription[
              j
            ].unit
              ? res.data.medicalPrescription[j].unit.id
              : "";
            for (
              var k = 0;
              k < res.data.medicalPrescription[j].alternative.length;
              k++
            ) {
              res.data.medicalPrescription[j].alternative[k].durationTime =
                res.data.medicalPrescription[j].alternative[k].durationTime.id;
              res.data.medicalPrescription[j].alternative[k].frequencyTime =
                res.data.medicalPrescription[j].alternative[k].frequencyTime.id;
              res.data.medicalPrescription[j].alternative[
                k
              ].routeOfAdministration = res.data.medicalPrescription[j]
                .alternative[k].routeOfAdministration
                ? res.data.medicalPrescription[j].alternative[k]
                    .routeOfAdministration.id
                : "";
              res.data.medicalPrescription[j].alternative[k].unit = res.data
                .medicalPrescription[j].alternative[k].unit
                ? res.data.medicalPrescription[j].alternative[k].unit.id
                : "";
            }
          }
        }

        this.historyOfPresentIllness =
          res.data.visitForm.historyOfPresentIllness;
        this.paraclinics = res.data.visitForm.paraclinics;
        this.differentialDiagnosis = res.data.visitForm.differentialDiagnosis;
        this.diagnosis = res.data.visitForm.diagnosis;
        this.plan = res.data.visitForm.plan;
        this.doctorNotes = res.data.visitForm.doctorNotes;

        this.form.patchValue({
          historyOfPresentIllness: res.data.visitForm.historyOfPresentIllness,
          heartRate: res.data.visitForm.heartRate,
          respiratoryRate: res.data.visitForm.respiratoryRate,
          temperature: res.data.visitForm.temperature,
          bloodPressureSystolic: res.data.visitForm.bloodPressureSystolic,
          bloodPressureDiastolic: res.data.visitForm.bloodPressureDiastolic,
          paraclinics: res.data.visitForm.paraclinics,
          differentialDiagnosis: res.data.visitForm.differentialDiagnosis,
          diagnosis: res.data.visitForm.diagnosis,
          plan: res.data.visitForm.plan,
          doctorNotes: res.data.visitForm.doctorNotes,
        });

        if (
          res.data.medicalPrescription &&
          res.data.medicalPrescription.length > 0
        ) {
          this.setExistMedicalPrescription(res.data.medicalPrescription, null);
        }

        if (
          res.data.visitForm.reviewOfSystem &&
          res.data.visitForm.reviewOfSystem.length > 0
        ) {
          this.form.setControl(
            "reviewOfSystem",
            this.setExistReviewSystem(res.data.visitForm.reviewOfSystem)
          );

          this.reviewOfSystemSubscription$.unsubscribe();
          this.reviewOfSystemSubscription$ = this.form
            .get("reviewOfSystem")
            .valueChanges.subscribe((data) => {
              return this.validateReviewOfSystem();
            });

          for (let subControl of (this.form.get("reviewOfSystem") as FormArray)
            .controls) {
            subControl.disable();
          }
        }

        if (
          res.data.paraclinicalPrescription &&
          res.data.paraclinicalPrescription.length > 0
        ) {
          this.form.setControl(
            "paraclinicalPrescription",
            this.setExistParaclinicalPrescription(
              res.data.paraclinicalPrescription
            )
          );
          for (let subControl of (this.form.get(
            "paraclinicalPrescription"
          ) as FormArray).controls) {
            subControl.disable();
          }
        }

        setTimeout(() => {
          this.enableUpdateBtn();
        }, 1000);
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  updateHistoryOfPresentIllness() {}

  setExistParaclinicalPrescription(list: any[]): FormArray {
    const epp = new FormArray([]);
    list.forEach((s) => {
      let newGroup = this.fb.group({
        _id: s._id,
        title: s.title,
        description: s.description,
      });
      epp.push(newGroup);
    });
    return epp;
  }

  setExistReviewSystem(list: any[]): FormArray {
    const fa = new FormArray([]);
    list.forEach((s) => {
      let newGroup = this.fb.group({
        bodySystem: s.id,
        value: s.value,
      });
      fa.push(newGroup);
    });
    return fa;
  }

  setExistMedicalPrescription(list: any, form) {
    ((form ?? this.form).get("medicalPrescription") as FormArray).clear();
    list.forEach((t) => {
      let mp: FormGroup = this.fb.group({
        _id: [""],
        name: [""],
        dosage: [""],
        unit: [""],
        routeOfAdministration: [""],
        frequency: [""],
        frequencyTime: [""],
        duration: [""],
        durationTime: [""],
        notes: [""],
        alternative: this.fb.array([]),
      });
      mp.disable();
      ((form ?? this.form).get("medicalPrescription") as FormArray).push(mp);

      t.alternative.forEach((b) => {
        var alter = this.fb.group({
          _id: [""],
          name: [""],
          dosage: [""],
          unit: [""],
          routeOfAdministration: [""],
          frequency: [""],
          frequencyTime: [""],
          duration: [""],
          durationTime: [""],
          notes: [""],
        });
        alter.disable();

        (mp.get("alternative") as FormArray).push(alter);
      });
    });

    (form ?? this.form).patchValue({
      medicalPrescription: list,
    });

    if (!form) {
      let XsA = <FormArray>this.form["controls"].medicalPrescription;
      const totalMedicalPrecriptions = XsA.length;

      for (let i = 0; i < totalMedicalPrecriptions; i++) {
        this.getNameUpdate(
          {
            target: {
              value: "dummyValue", // This values is not doing anything, just a dummy value to fire the function
            },
          },
          i
        );
      }
    }
  }

  validateReviewOfSystem() {
    {
      let RoSA = <FormArray>this.form["controls"].reviewOfSystem;
      this.formErrors.reviewOfSystem = [];
      let rr = 1;
      while (rr <= RoSA.length) {
        this.formErrors.reviewOfSystem.push({
          bodySystem: "",
          value: "",
        });
        let RR = <FormGroup>RoSA.at(rr - 1);

        if (RR.get("bodySystem").value != "") {
          if (RR.get("value").validator === null) {
            RR.get("value").setValidators([Validators.required]);
          }
        }
        if (
          RR.get("bodySystem").value == "" ||
          RR.get("bodySystem").value == null ||
          RR.get("bodySystem").value == undefined
        ) {
          if (RR.get("value").validator !== null) {
            RR.get("value").clearValidators();
            RR.get("value").updateValueAndValidity();
          }
        }

        if (RR.get("value").value.length > 0) {
          if (RR.get("bodySystem").validator === null) {
            RR.get("bodySystem").setValidators([Validators.required]);
          }
        }

        if (RR.get("value").value.length < 1) {
          if (RR.get("bodySystem").validator !== null) {
            RR.get("bodySystem").clearValidators();
            RR.get("bodySystem").updateValueAndValidity();
          }
        }

        for (let field in RR.controls) {
          let input = RR.get(field);

          if (input.invalid && input.dirty) {
            for (let error in input.errors) {
              this.formErrors.reviewOfSystem[rr - 1][
                field
              ] = this.validationMessages.reviewOfSystem[field][error];
            }
          }

          console.log("formErrors", this.formErrors);
        }
        rr++;
      }
    }
  }

  validatePcp() {
    {
      let PcpA = <FormArray>this.form["controls"].paraclinicalPrescription;
      this.formErrors.paraclinicalPrescription = [];
      let pp = 1;
      while (pp <= PcpA.length) {
        this.formErrors.paraclinicalPrescription.push({
          title: "",
          description: "",
        });
        let PP = <FormGroup>PcpA.at(pp - 1);

        if (PP.get("title").value.length > 0) {
          if (PP.get("description").validator === null) {
            PP.get("description").setValidators([Validators.required]);
          }
        }
        if (PP.get("title").value.length < 1) {
          if (PP.get("description").validator !== null) {
            PP.get("description").clearValidators();
            PP.get("description").updateValueAndValidity();
          }
        }

        if (PP.get("description").value.length > 0) {
          if (PP.get("title").validator === null) {
            PP.get("title").setValidators([Validators.required]);
          }
        }

        if (PP.get("description").value.length < 1) {
          if (PP.get("title").validator !== null) {
            PP.get("title").clearValidators();
            PP.get("title").updateValueAndValidity();
          }
        }

        for (let field in PP.controls) {
          let input = PP.get(field);

          if (input.invalid && input.dirty) {
            for (let error in input.errors) {
              this.formErrors.paraclinicalPrescription[pp - 1][
                field
              ] = this.validationMessages.paraclinicalPrescription[field][
                error
              ];
            }
          }
        }
        console.log("formErrors nuevos...", this.formErrors);
        pp++;
      }
    }
  }

  getBodySystem() {
    this.dashboardService.getbodySystem().subscribe(
      (res: any) => {
        this.bodysystem = res;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  getCheifComplaintsList() {
    this.dashboardService.getChiefComplaintList().subscribe(
      (res: any) => {
        this.chiefComplaintList = res;
        this.otherChiefComplaintField();

        let preselectValue = this.chiefComplaintList.filter(
          (item) => item._id == this.currentVisitDetails.chiefComplaint.id
        );

        if (preselectValue[0].isOther == true) {
          this.form.patchValue({
            otherChiefComplaint: this.currentVisitDetails.chiefComplaint.other,
          });

          this.form.patchValue({
            chiefComplaint: this.currentVisitDetails.chiefComplaint.other,
          });
        } else {
          this.form.patchValue({
            chiefComplaint: preselectValue[0].chiefComplaint.es,
          });
        }
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  otherChiefComplaintField() {
    var tempOtherChiefComplaint = this.form.get("otherChiefComplaint");
    this.form.get("chiefComplaint").valueChanges.subscribe((val) => {
      if (val != null && val != undefined && val != "") {
        if (val.isOther == true) {
          this.showOtherChiefComplaint = true;
          tempOtherChiefComplaint.setValidators([Validators.required]);
          tempOtherChiefComplaint.markAsDirty();
          tempOtherChiefComplaint.markAsTouched();
          tempOtherChiefComplaint.updateValueAndValidity();
        } else {
          this.showOtherChiefComplaint = false;
          tempOtherChiefComplaint.setValue("");
          tempOtherChiefComplaint.clearValidators();
          tempOtherChiefComplaint.updateValueAndValidity();
        }
      } else {
        this.showOtherChiefComplaint = false;
        tempOtherChiefComplaint.setValue("");
        tempOtherChiefComplaint.clearValidators();
        tempOtherChiefComplaint.updateValueAndValidity();
      }
    });
  }

  getUnits() {
    this.dashboardService.getUnits().subscribe(
      (res: any) => {
        this.Units = res["data"];
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  getTablete() {
    this.dashboardService.getTimes().subscribe(
      (res: any) => {
        this.tablete = res["data"];
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  getRouteofAdmininstration() {
    this.dashboardService.getRoute().subscribe(
      (res: any) => {
        this.adminstrations = res["data"];
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  getClosureReasons() {
    this.dashboardService.getClosureReasons().subscribe(
      (res: any) => {
        this.closureReasons = res;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  initReviewOfSystem() {
    return this.fb.group({
      bodySystem: [""],
      value: [""],
    });
  }

  addReviewOfSystem() {
    const control = <FormArray>this.form.controls["reviewOfSystem"];
    control.push(this.initReviewOfSystem());
  }

  removeReviewOfSystem(ir) {
    ((<FormArray>this.form.controls["reviewOfSystem"]) as FormArray).removeAt(
      ir
    );
  }

  initParaClinicalPrescription() {
    return this.fb.group({
      title: [""],
      description: [""],
    });
  }

  addParaClinicalPrescription() {
    const control = <FormArray>this.form.controls["paraclinicalPrescription"];
    control.push(this.initParaClinicalPrescription());
  }

  removeParaClinicalPrescription(ip) {
    ((<FormArray>(
      this.form.controls["paraclinicalPrescription"]
    )) as FormArray).removeAt(ip);
  }

  initX() {
    return this.fb.group({
      //  ---------------------forms fields on x level ------------------------

      name: [""],
      dosage: [{ value: "", disabled: true }],
      unit: [{ value: "", disabled: true }],
      routeOfAdministration: [{ value: "", disabled: true }],
      frequency: [{ value: "", disabled: true }],
      frequencyTime: [{ value: "", disabled: true }],
      duration: [{ value: "", disabled: true }],
      durationTime: [{ value: "", disabled: true }],
      notes: [{ value: "", disabled: true }],
      // ---------------------------------------------------------------------
      alternative: this.fb.array([
        //this.initY()
      ]),
    });
  }

  initY() {
    return this.fb.group({
      //  ---------------------forms fields on y level ------------------------

      name: [""],
      dosage: [{ value: "", disabled: true }],
      unit: [{ value: "", disabled: true }],
      routeOfAdministration: [{ value: "", disabled: true }],
      frequency: [{ value: "", disabled: true }],
      frequencyTime: [{ value: "", disabled: true }],
      duration: [{ value: "", disabled: true }],
      durationTime: [{ value: "", disabled: true }],
      notes: [{ value: "", disabled: true }],
      // ---------------------------------------------------------------------
    });
  }

  addX(forFollowUp: boolean) {
    const control = forFollowUp
      ? <FormArray>this.mpFollowUpForm["controls"].medicalPrescription
      : <FormArray>this.form.controls["medicalPrescription"];
    control.push(this.initX());
  }

  addY(ix, forFollowUp: boolean) {
    const control = (forFollowUp
      ? <FormArray>this.mpFollowUpForm["controls"].medicalPrescription
      : <FormArray>this.form.controls["medicalPrescription"]
    )
      .at(ix)
      .get("alternative") as FormArray;
    control.push(this.initY());
  }

  formErrors = {
    medicalPrescription: this.medicalPrescriptionErrors(),
    paraclinicalPrescription: this.paraclinicalPrescriptionErrors(),
    reviewOfSystem: this.reviewOfSystemErrors(),
  };

  reviewOfSystemErrors() {
    return [
      {
        bodySystem: "",
        value: "",
      },
    ];
  }

  paraclinicalPrescriptionErrors() {
    return [
      {
        title: "",
        description: "",
      },
    ];
  }

  medicalPrescriptionErrors() {
    return [
      {
        //  ---------------------forms errors on x level ------------------------
        name: "",
        dosage: "",
        unit: "",
        routeOfAdministration: "",
        frequency: "",
        frequencyTime: "",
        duration: "",
        durationTime: "",
        notes: "",

        // ---------------------------------------------------------------------
        alternative: this.alternativeErrors(),
      },
    ];
  }

  alternativeErrors() {
    return [
      {
        //  ---------------------forms errors on y level ------------------------
        name: "",
        dosage: "",
        unit: "",
        routeOfAdministration: "",
        frequency: "",
        frequencyTime: "",
        duration: "",
        durationTime: "",
        notes: "",
        // ----------------------------------------------------------------------
      },
    ];
  }

  validationMessages = {
    medicalPrescription: {
      name: {
        required: "name is required.",
      },
      dosage: {
        required: "dosage is required.",
      },
      unit: {
        required: "unit is required.",
        pattern: "unit must be 3 characters long.",
      },
      routeOfAdministration: {
        required: "routeOfAdministration is required.",
        pattern: "routeOfAdministration must be 3 characters long.",
      },
      frequency: {
        required: "frequency is required.",
        pattern: "frequency must be 3 characters long.",
      },
      duration: {
        required: "duration is required.",
        pattern: "duration must be 3 characters long.",
      },
      frequencyTime: {
        required: "frequencyTime is required.",
        pattern: "frequencyTime must be 3 characters long.",
      },
      durationTime: {
        required: "durationTime is required.",
        pattern: "durationTime must be 3 characters long.",
      },
      notes: {
        required: "notes is required.",
        pattern: "notes must be 3 characters long.",
      },
      alternative: {
        name: {
          required: "name is required.",
        },
        dosage: {
          required: "dosage is required.",
        },
        unit: {
          required: "unit is required.",
          pattern: "unit must be 3 characters long.",
        },
        routeOfAdministration: {
          required: "routeOfAdministration is required.",
          pattern: "routeOfAdministration must be 3 characters long.",
        },
        frequency: {
          required: "frequency is required.",
          pattern: "frequency must be 3 characters long.",
        },
        duration: {
          required: "duration is required.",
          pattern: "duration must be 3 characters long.",
        },
        frequencyTime: {
          required: "frequencyTime is required.",
          pattern: "frequencyTime must be 3 characters long.",
        },
        durationTime: {
          required: "durationTime is required.",
          pattern: "durationTime must be 3 characters long.",
        },
        notes: {
          required: "notes is required.",
          pattern: "notes must be 3 characters long.",
        },
      },
    },
    paraclinicalPrescription: {
      title: {
        title: "title is required.",
      },
      description: {
        description: "description is required.",
      },
    },
    reviewOfSystem: {
      bodySystem: {
        bodySystem: "bodySystem is required.",
      },
      value: {
        value: "value is required.",
      },
    },
  };

  removeY(ix, iy, forFollowUp) {
    ((forFollowUp
      ? <FormArray>this.mpFollowUpForm["controls"].medicalPrescription
      : <FormArray>this.form.controls["medicalPrescription"]
    )
      .at(ix)
      .get("alternative") as FormArray).removeAt(iy);
  }

  removeX(ix, forFollowUp) {
    ((forFollowUp
      ? <FormArray>this.mpFollowUpForm["controls"].medicalPrescription
      : <FormArray>(
          this.form.controls["medicalPrescription"]
        )) as FormArray).removeAt(ix);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form["controls"]["medicalPrescription"]["controls"];
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  formSubmit() {
    this.form.enable();

    this.setPlan(this.form.get("plan").value ?? "");
    this.setDiagnosis(this.form.get("diagnosis").value ?? "");
    this.setHistoryOfPresentIllness(
      this.form.get("historyOfPresentIllness").value ?? ""
    );
    console.log(this.form);
    if (!this.form.valid) {
      this.translateService
        .get("Please fill the required fields in visit summary form")
        .subscribe((text: string) => {
          alert(text);
        });

      if (this.singPatientRecord?.consultation?.isFollowUp) {
        this.form.disable();
        this.closeAttentionForm.enable();
      }

      return;
    } else {
      let ppList = this.form.value["paraclinicalPrescription"];
      for (var f = 0; f < ppList.length ? ppList.length : 0; f++) {
        if (ppList[f].title && !ppList[f].description) {
          this.translateService
            .get("Please enter description in Paraclinical Prescription")
            .subscribe((text: string) => {
              alert(text);
            });
          return;
        } else if (!ppList[f].title && ppList[f].description) {
          this.translateService
            .get("Please enter title in Paraclinical Prescription")
            .subscribe((text: string) => {
              alert(text);
            });
          return;
        }
      }

      let ppp = this.form.value["paraclinicalPrescription"].filter(
        (item) => item.title != ""
      );
      let ros = this.form.value["reviewOfSystem"].filter(
        (item) => item.bodySystem != ""
      );
      let mpl = this.form.value["medicalPrescription"];
      mpl = this.treatMedicalPrescriptions(mpl);

      let data = {
        ...this.form.value,
      };

      const pattern = /^\d{0,3}(\.\d{0,2})?$/;
      if (data.temperature && !pattern.test(data.temperature)) {
        this.translateService
          .get("Enter the valid temperature.")
          .subscribe((text: string) => {
            alert(text);
          });
        return;
      }

      data["paraclinicalPrescription"] = ppp;
      data["reviewOfSystem"] = ros;
      data["medicalPrescription"] = mpl;

      let selectedChiefComplaint = this.chiefComplaintList.filter(
        (chiefComplaint) => {
          return (
            this.form.value["chiefComplaint"] ===
            chiefComplaint.chiefComplaint.es
          );
        }
      );

      if (selectedChiefComplaint.length === 0) {
        selectedChiefComplaint = this.chiefComplaintList.filter(
          (chiefComplaint) => {
            return chiefComplaint.chiefComplaint.es === "Otro";
          }
        );

        data["otherChiefComplaint"] = this.form.value["chiefComplaint"];
      }

      data["chiefComplaint"] = selectedChiefComplaint[0]._id;

      // if (this.singPatientRecord.consultation.isFollowUp != true) {
      data["hasFollowUp"] = this.followUpInfo.hasFollowUp;
      data["followUpDate"] = this.followUpInfo.followUpDate;
      // data["followUpDate"] = this.followUpCall;
      // }

      let apiData = this.removeEmptyKeys(data);

      if (
        !this.followCallingUp &&
        apiData.closeAttentionForm &&
        apiData.closeAttentionForm.closeAttention
      ) {
        apiData = {
          ...apiData,
          ...apiData.closeAttentionForm,
        };
      }

      if (this.singPatientRecord.consultation.isFollowUp == true) {
        let followUpMpl = this.mpFollowUpForm["controls"].medicalPrescription
          .value;
        followUpMpl = this.treatMedicalPrescriptions(followUpMpl);
        data["medicalPrescription"] = followUpMpl;
        apiData = this.removeEmptyKeys(apiData);

        this.dashboardService
          .updateVisitHistory(this.consultId, apiData)
          .subscribe(
            (res: any) => {
              this.translateService
                .get("Visit History details updated successfully")
                .subscribe((text: string) => {
                  this.sharedService.showMessage(text, "success");
                });
              this.router.navigate([""]);
            },
            (error: HttpErrorResponse) => {
              this.handleError(error);

              if (
                this.isOriginalDoctor &&
                !this.singPatientRecord?.consultation?.isFollowUp
              ) {
                this.form.enable();
                this.isFollowUpEditEnabled = true;
                this.isRegularFormEnabled = true;
              } else {
                this.isFollowUpEditEnabled = true;
                this.isRegularFormEnabled = false;
                this.form.disable();
              }
            }
          );
      } else {
        this.dashboardService
          .createVisitSummary(this.consultId, apiData)
          .subscribe(
            (res: any) => {
              this.translateService
                .get("Visit History details created successfully")
                .subscribe((text: string) => {
                  this.sharedService.showMessage(text, "success");
                });
              this.router.navigate([""]);
            },
            (error: HttpErrorResponse) => {
              this.handleError(error);
            }
          );
      }
    }

    this.form.disable();
  }

  treatMedicalPrescriptions(mpl) {
    if (mpl.filter((item) => item.name === "" && item.frequency).length > 0) {
      this.translateService
        .get("Medicine name is required")
        .subscribe((text: string) => {
          alert(text);
        });
      return;
    } else {
      mpl = mpl.filter((item) => item.name != "");
      mpl = mpl.map((x) => ({
        ...x,
        frequency: parseInt(x.frequency),
        duration: parseInt(x.duration),
      }));
    }

    for (var i = 0; i < mpl.length; i++) {
      mpl[i] = Object.entries(mpl[i]).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      ); //Remove empty values
      let alternative =
        mpl[i].alternative && mpl[i].alternative.length > 0
          ? mpl[i].alternative.filter((item) => item.name != "")
          : [];
      alternative = alternative.map((x) => ({
        ...x,
        frequency: parseInt(x.frequency),
        duration: parseInt(x.duration),
      }));
      alternative = alternative.map((aItem) => {
        aItem = Object.entries(aItem).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        ); //Remove empty values
        return aItem;
      });
      if (alternative.filter((item) => item.name === "").length > 0) {
        this.translateService
          .get("Medicine name is required")
          .subscribe((text: string) => {
            alert(text);
          });
        return;
      } else {
        mpl[i].alternative = alternative;
      }
    }

    return mpl;
  }

  removeEmptyKeys(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === "" ||
        obj[propName].length < 1
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  _keyPress(event: any) {
    const pattern = /^\d{0,3}(\.\d{0,2})?$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  testCalling(data) {
    if (data === true) {
      this.isTestCall = true;
      this.istestingCallingUp = data;
      this.form.disable();
    } else {
      this.isTestCall = false;
      this.form.enable();
    }
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error
      if (error.status == 422) {
        let values = [];
        const messages = error.error.errors.messages;
        for (let key in messages) {
          Object.keys(messages[key]).forEach((errorKey) => {
            values.push(messages[key][errorKey]);
          });
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  documentsGetImage(data) {
    var url = new URL(data.url);
    var path = url.pathname;
    const imageExtension = path.split(".").pop().toLowerCase();
    if (imageExtension === "png") {
      return "png";
    }
    if (imageExtension === "jpg" || imageExtension === "jpeg") {
      return "jpg";
    }
    if (imageExtension === "pdf") {
      return "pdf";
    }
    if (imageExtension === "docx" || imageExtension === "doc") {
      return "doc";
    }
    if (
      imageExtension === "xls" ||
      imageExtension === "xlsx" ||
      imageExtension === "csv"
    ) {
      return "xsl";
    }
  }

  attachmentPopup(attachment) {
    const dialogRef = this.dialog.open(AttachmentPopupComponent, {
      width: "600px",
      maxHeight: "500px",
      data: { document: attachment._id, status: false },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  ngOnDestroy() {
    this.vsSubmitSubscription.unsubscribe();
    this.vscallStatusSubscription.unsubscribe();
  }

  enableUpdateBtn() {
    this.historyOfPresentIllness = this.form.get(
      "historyOfPresentIllness"
    ).value;
    this.paraclinics = this.form.get("paraclinics").value;
    this.differentialDiagnosis = this.form.get("differentialDiagnosis").value;
    this.diagnosis = this.form.get("diagnosis").value;
    this.plan = this.form.get("plan").value;
    this.doctorNotes = this.form.get("doctorNotes").value;

    this.followUpCall.subjective = this.form.controls["followUpForm"].get(
      "subjective"
    ).value;
    this.followUpCall.objective = this.form.controls["followUpForm"].get(
      "objective"
    ).value;
    this.followUpCall.assesment = this.form.controls["followUpForm"].get(
      "assesment"
    ).value;
    this.followUpCall.plan = this.form.controls["followUpForm"].get(
      "plan"
    ).value;

    console.log(
      this.isOriginalDoctor,
      !this.singPatientRecord?.consultation?.isFollowUp,
      "ULTIMO"
    );

    if (
      this.isOriginalDoctor &&
      !this.singPatientRecord?.consultation?.isFollowUp
    ) {
      this.form.enable();
      this.isFollowUpEditEnabled = true;
      this.isRegularFormEnabled = true;
    } else {
      this.isFollowUpEditEnabled = true;
      this.form.disable();
      this.isRegularFormEnabled = false;
    }
    this.closeAttentionForm.enable();
  }

  getErrorMessage(formControl) {
    return "Campo requerido";
  }

  closeAttentionChange(event) {
    const closureReasonControl = this.closeAttentionForm.get("closureReason");
    closureReasonControl.reset();
    closureReasonControl.setValidators([]);
    closureReasonControl.updateValueAndValidity();

    const otherClosureReasonControl = this.closeAttentionForm.get(
      "otherClosureReason"
    );
    otherClosureReasonControl.reset();
    otherClosureReasonControl.setValidators([]);
    otherClosureReasonControl.updateValueAndValidity();

    if (event && event.checked) {
      closureReasonControl.setValidators([Validators.required]);
      closureReasonControl.updateValueAndValidity();

      this.isOtherClosureReason = false;
    }
  }

  closureReasonChange(event) {
    if (event && event.value) {
      const closureReason = this.closureReasons.find((closureReason) => {
        return closureReason._id === event.value;
      });

      this.isOtherClosureReason = closureReason && closureReason.isOther;
    } else {
      this.isOtherClosureReason = false;
    }

    const otherClosureReasonControl = this.closeAttentionForm.get(
      "otherClosureReason"
    );
    otherClosureReasonControl.reset();

    if (this.isOtherClosureReason) {
      otherClosureReasonControl.setValidators([Validators.required]);
      otherClosureReasonControl.updateValueAndValidity();
    } else {
      otherClosureReasonControl.setValidators([]);
      otherClosureReasonControl.updateValueAndValidity();
    }
  }
}
