import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "../dashboard.service";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { CommonService } from "../common.services";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/shared/shared.service";

@Component({
  selector: "app-postpone-popup",
  templateUrl: "./postpone-popup.component.html",
  styleUrls: ["./postpone-popup.component.scss"],
})
export class PostponePopupComponent implements OnInit {
  consultId: any;
  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private commonServices: CommonService,
    private translationService: TranslateService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.consultId = params.id;
    });
  }
  postponeCall() {
    this.dashboardService.postponeCallByDoctor(this.consultId).subscribe(
      (res: any) => {
        this.translationService
          .get("Call has been Postponed Successfully")
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });

        this.router.navigate([""]);
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
