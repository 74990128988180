<!-- <div class="example-boundary"> -->
<div class="example-box" cdkDragLockAxis="x" cdkDrag>
  <div class="example-handle" cdkDragHandle>
    <svg width="14px" fill="currentColor" viewBox="0 0 24 24">
      <path
        d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
      ></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  </div>

  <div id="chat-container">
    <div
      style="
        position: absolute;
        min-height: 35px;
        top: 0px;
        z-index: 100;
        width: 100%;
        color: white;
        font-weight: bold;
        background: #46535b;
        box-shadow: -2px 4px 22px rgba(0, 0, 0, 0.26);
      "
    >
      <img
        *ngIf="profilePic; else defaultProfile"
        [src]="fileUrl + profilePic"
        width="20px"
        height="20px"
        style="
          border-radius: 50%;
          position: relative;
          margin-left: 5%;
          margin-top: 3%;
        "
      />
      <ng-template #defaultProfile>
        <!-- <ngx-avatar class="chat-user-profile" [name]="userData.patient.firstName + ' ' + userData.patient.lastName" size="20"></ngx-avatar> -->
        <img
          src="../../../assets/images/person-icon.png"
          width="20px"
          height="20px"
          style="
            border-radius: 50%;
            position: relative;
            margin-left: 5%;
            margin-top: 3%;
          "
        />
      </ng-template>
      <span
        style="
          top: 10px;
          margin-left: 10px;
          position: absolute;
          text-transform: capitalize;
        "
      >
        {{ userData.patient.firstName + " " + userData.patient.lastName }}
      </span>
      <span style="top: 10px; margin-left: 170px; position: absolute">{{
        transform(time)
      }}</span>
    </div>

    <div #msgContainer id="msg-container" (scroll)="getPreviousHistory()">
      <div *ngFor="let item of chatData; let i = index" class="message">
        <div style="width: 100%" id="{{ i }}">
          <div
            class="{{
              item.sender_id == this.senderId ? 'right-msg' : 'left-msg'
            }}"
          >
            <div
              title="{{ item.date_sent * 1000 | date: 'short' }}"
              class="display"
            >
              <div>{{ item.message }}</div>
              <div
                class="{{
                  item.sender_id == this.senderId
                    ? 'right-side-date'
                    : 'left-side-date'
                }}"
                style="font-size: 10px; color: black"
              >
                {{ item.date_sent * 1000 | date: "short" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="typeBox">
      <input
        (keyup.enter)="onEnter(qbChatUserId, message)"
        type="text"
        [(ngModel)]="message"
        placeholder="{{ 'Send Message' | translate }}"
        style="margin-left: 4%"
      />
      <img
        src="../../../assets/images/end.svg"
        width="30px"
        height="30px"
        (click)="disConnect()"
        style="
          cursor: pointer;
          border-radius: 50%;
          position: absolute;
          right: 5%;
        "
      />
    </div>
  </div>
</div>
