export const qbEndpoints = {
  api_endpoint: "https://api.quickblox.com",
  chat_endpoint: "chat.quickblox.com",
  turnserver_endpoint: "turnserver.quickblox.com",
  passString: "Sinapsis@",
  webrtc: {
    autoReject: true,
    incomingLimit: 1,
    answerTimeInterval: 180, // Max answer time after that the 'QB.webrtc.onUserNotAnswerListener' callback will be fired.
    dialingTimeInterval: 5, // The interval between call requests produced by session.call(extension)
    disconnectTimeInterval: 30, // If an opponent lost a connection then after this time the caller will now about it via 'QB.webrtc.onSessionConnectionStateChangedListener' callback.
    statsReportTimeInterval: false, // Allows access to the statistical information about a peer connection. You can set amount of seconds you can get statistic information after.
  },
};
