import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-visit-history-follow-up-item",
  templateUrl: "./visit-history-follow-up-item.component.html",
  styleUrls: ["./visit-history-follow-up-item.component.scss"],
})
export class VisitHistoryFollowUpItemComponent implements OnInit {
  @Input() doctorName: string = "";
  @Input() date: string = "";
  @Input() pos: string = "";
  @Input() hasNext: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
