import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Input,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DashboardService } from "../dashboard.service";
import { DataStoreServiceService } from "../data-store-service.service";
import { ModalService } from "../modal.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { AttachmentPopupComponent } from "../attachment-popup/attachment-popup.component";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "src/app/shared/shared.service";
import { ViewAttachmentComponent } from "../view-attachment/view-attachment.component";
import { ConnectionService } from "../connection.service";
import { UploadFileComponent } from "../upload-file/upload-file.component";

export class PageParams {
  patient: string;
  tag: string;
  size: number;
  skip: number;
}

@Component({
  selector: "app-attachments",
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"],
})
export class AttachmentsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() canAdd: boolean;
  totalLength: boolean = false;
  tags: any = [];
  docsList: any = [];
  documentFilter: any = [];
  pageParams: Partial<PageParams> = { size: 10, skip: 0 };
  tagId: any = [];
  patientId;
  patientDetials: any;
  filterId;
  filterAttachement = false;
  loading: boolean = true;
  attachmentInCall: boolean = false;
  callStatus: boolean = false;
  tagSequence = [];
  callFeedbackSubscription: Subscription;
  currentLanguage: string;
  sockectSubscription: Subscription;
  public attachmentOnCallRecord: any;

  constructor(
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private store: DataStoreServiceService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private translateService: TranslateService,
    private sharedService: SharedService,
    private connectionService: ConnectionService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.patientId = params.patient;
      this.getPatientDetails(this.patientId);
      this.docsList = [];
      this.pageParams.skip = 0;
      this.getDocuments();
    });

    this.modalService.vscallStatusObservable.subscribe((res) => {
      if (res.callStarted == true) {
        this.callStatus = true;
        this.sockectSubscription = this.connectionService.messages.subscribe(
          (msg) => {
            if (msg.data.url) {
              this.attachmentOnCallRecord = msg["data"];
              this.viewAttachment();
            }
          }
        );
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}

  viewAttachment() {
    const dialogRef = this.dialog.open(ViewAttachmentComponent, {
      width: "402px",
      disableClose: true,
      data: { record: this.attachmentOnCallRecord },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.docsList = [];
      let replace = true;
      this.pageParams.skip = 0;
      this.pageParams.tag = "";
      this.getDocuments(replace);
    });
  }
  ngOnInit() {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
    } else {
      this.currentLanguage = "es";
    }

    this.getallTags();
    this.callFeedbackSubscription = this.modalService.callFeedBackObservable.subscribe(
      (res) => {
        if (res.callStatus == 2) {
          this.callStatus = false;
          if (this.sockectSubscription) {
            this.sockectSubscription.unsubscribe();
          }
        }
      }
    );
  }

  attachmentPopup(attachment) {
    const dialogRef = this.dialog.open(AttachmentPopupComponent, {
      width: "600px",
      maxHeight: "500px",
      data: { document: attachment._id, status: this.callStatus },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getallTags() {
    this.dashboardService.getTags().subscribe(
      (res: any) => {
        this.tags = res["data"];
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }
  documentsGetImage(data) {
    var url = new URL(data.url);
    var path = url.pathname;
    const imageExtension = path.split(".").pop().toLowerCase();
    if (imageExtension === "png") {
      return "png";
    }
    if (imageExtension === "jpg" || imageExtension === "jpeg") {
      return "jpg";
    }
    if (imageExtension === "pdf") {
      return "pdf";
    }
    if (imageExtension === "docx" || imageExtension === "doc") {
      return "doc";
    }
    if (
      imageExtension === "xls" ||
      imageExtension === "xlsx" ||
      imageExtension === "csv"
    ) {
      return "xsl";
    }
  }

  getDocumentsFilter(data) {
    this.pageParams.tag = data ? data : "";
    this.pageParams.skip = 0;
    let replace = true;
    this.getDocuments(replace);
  }

  pagination() {
    this.pageParams.skip = this.pageParams.skip + 10;
    this.getDocuments();
  }

  getDocuments(replace?: boolean) {
    this.pageParams.patient = this.patientId;
    delete this.pageParams.tag;
    this.dashboardService.getDocuments(this.pageParams).subscribe(
      (res: any) => {
        if (replace) {
          this.docsList = res["data"];
        } else {
          for (var i = 0; i < res["data"].length; i++) {
            this.docsList.push(res["data"][i]);
          }
        }
        if (this.docsList.length == res.meta.total) {
          this.totalLength = true;
        } else {
          this.totalLength = false;
        }
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
        this.loading = false;
      }
    );
  }

  getPatientDetails(patientId) {
    this.dashboardService.getPatientDetials(patientId).subscribe(
      (res: any) => {
        this.patientDetials = res;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  uploadFile() {
    const uploadRef = this.dialog.open(UploadFileComponent, {
      width: "600px",
      disableClose: true,
      autoFocus: false,
      data: {
        patientDetials: this.patientDetials,
      },
    });

    uploadRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        switch (result.event) {
          case "refreshList":
            this.getDocuments(true);
            break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.callFeedbackSubscription.unsubscribe();
    if (this.sockectSubscription) {
      this.sockectSubscription.unsubscribe();
    }
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
