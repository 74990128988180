<div class="background">
  <div class="left-bg">
    <img
      class="bottom-right-watermark"
      src="../../assets/images/holadoc-corazon.svg"
    />
    <div class="login-container">
      <div class="container">
        <mat-card class="login-card">
          <div>
            <img src="../../assets/images/logo.svg" width="90px" />
          </div>
          <form class="form" [formGroup]="loginForm" (ngSubmit)="login()">
            <p *ngIf="showError" style="font-weight: bold; color: #ee2029">
              {{ error }}
            </p>
            <!-- Email -->
            <mat-form-field class="login-form-field" style="width: 100%">
              <input
                type="text"
                matInput
                autocomplete="email"
                placeholder="{{ 'Email' | translate }}"
                formControlName="email"
              />
              <mat-error *ngIf="loginForm.get('email').hasError('required')">
                {{ "Email is Required." | translate }}
              </mat-error>
              <mat-error *ngIf="loginForm.get('email').hasError('pattern')">
                {{ "Please enter a valid email" | translate }}
              </mat-error>
            </mat-form-field>
            <!-- Password -->
            <mat-form-field class="login-form-field" style="width: 100%">
              <input
                type="password"
                matInput
                autocomplete="password"
                placeholder="{{ 'Password' | translate }}"
                formControlName="password"
              />
              <mat-error *ngIf="loginForm.get('password').hasError('required')">
                {{ "Password is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="loginForm.get('password').hasError('minlength')"
              >
                {{ "Password should be minimum of 8 characters." | translate }}
              </mat-error>
              <mat-error
                *ngIf="loginForm.get('password').hasError('maxlength')"
              >
                {{ "Password should be maximum of 15 characters." | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  loginForm.get('password').hasError('pattern') &&
                  !loginForm.get('password').hasError('minlength') &&
                  !loginForm.get('password').hasError('maxlength')
                "
              >
                {{ "Please enter valid password." | translate }}
              </mat-error>
            </mat-form-field>
            <br /><br />
            <div>
              <!-- <div>
            <mat-checkbox
              color="primary"
              class="login-form-remember"
              formControlName="remember"
              >{{ "Remember me" | translate }}</mat-checkbox
            >
          </div> -->
              <div class="login-form-forgot">
                <a
                  style="color: #46535b; text-decoration: none"
                  routerLink="/forgot-pwd"
                  >{{ "Forgot password?" | translate }}</a
                >
              </div>
              <!-- <div class="login-form-forgot">
                <a
                  style="color: #46535b; text-decoration: none"
                  routerLink="/login-2FA"
                  >{{ "2FA" | translate }}</a
                >
              </div> -->
            </div>
            <div class="login-button-row">
              <button [disabled]="isLoading" mat-raised-button>
                {{ "Continue" | translate }}
              </button>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
</div>
