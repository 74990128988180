import { Component, OnInit } from "@angular/core";
import { ModalService } from "../modal.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-reconnectin-popup",
  templateUrl: "./reconnectin-popup.component.html",
  styleUrls: ["./reconnectin-popup.component.scss"],
})
export class ReconnectinPopupComponent implements OnInit {
  public selection: string = "2";

  constructor(
    private modalService: ModalService,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {}
  reconnect = [
    {
      name: "Video Call",
      value: "Video call allows you to have a video call with doctor",
    },
    {
      name: "Phone Call",
      value: "Phone call allows you to have a call with doctor",
    },
    // {
    //   name: "Chat",
    //   value: "Chat allows you to have a chat with doctor",
    // },
  ];

  getReconnectValue() {
    this.modalService.reconnectSubject.next({
      status: parseInt(this.selection),
    });
    this.matDialog.closeAll();
  }
}
