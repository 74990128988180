import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { CredentialsService } from "../core/credentials.service";
import { AuthenticationService } from "../core/authentication.service";
import { SharedService } from "../shared/shared.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-login2-fa",
  templateUrl: "./login2-fa.component.html",
  styleUrls: ["./login2-fa.component.scss"],
})
export class Login2FAComponent implements OnInit {
  //First step
  login2FAForm: FormGroup;
  isLoading = false;
  methodSelected: string = "Email";
  methodsFor2FA = ["Email", "SMS"];
  email: string = "";

  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService,
    private translationService: TranslateService
  ) {}

  ngOnInit(): void {
    this.email = this.credentialsService.credentials.username;
  }

  send2FACode() {
    this.isLoading = true;
    const request2FA$ = this.authenticationService.request2FA(
      this.email,
      this.methodSelected === "SMS"
    );
    request2FA$
      .pipe(
        finalize(() => {
          // this.loginForm.markAsPristine();
        })
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.router.navigateByUrl(`/validate-2FA/${this.methodSelected}`);
        },

        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.handleError(error);
        }
      );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status === 404) {
        this.translationService
          .get(
            "The user entered is not registered. Please contact the HolaDOC team"
          )
          .subscribe((text: string) => {
            this.sharedService.showErrorMessage(text, "single");
          });
      } else if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  doBack() {
    console.log("doBack");
    history.back();
  }
}
