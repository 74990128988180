<div>
  <mat-icon style="float: right; cursor: pointer" mat-dialog-close
    >close</mat-icon
  >
  <h5 class="heading">
    {{
      "Are you sure you want to end the call and complete filling form?"
        | translate
    }}
  </h5>
  <div mat-dialog-actions class="sucessBtn">
    <span style="cursor: pointer" mat-dialog-close>{{
      "Cancel" | translate
    }}</span>
    <button mat-raised-button (click)="sucessCall()">
      {{ "End" | translate }}
    </button>
  </div>
</div>
