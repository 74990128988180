<div class="register-container">
  <app-header></app-header>
  <div class="container">
    <h4 class="heading">{{ "Registration" | translate }}</h4>
    <p class="subheading">
      {{
        "Welcome to HolaDOC. Please fill up the below from we on-board you!"
          | translate
      }}
    </p>
    <form
      class="example-form"
      [formGroup]="registrationForm"
      (ngSubmit)="createDoctor()"
    >
      <div class="grid-container">
        <div class="grid-item">
          <mat-card style="padding-top: 1%">
            <p style="text-align: initial; color: #46535b">
              {{ "Personal Information" | translate }}
            </p>
            <mat-form-field
              class="example-full-width my-form-field"
              style="width: 100%"
            >
              <input
                type="text"
                matInput
                autocomplete="firstName"
                formControlName="firstName"
                placeholder="{{ 'First Name' | translate }}"
              />
              <mat-error
                *ngIf="registrationForm.get('firstName').hasError('required')"
              >
                {{ "First Name is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="registrationForm.get('firstName').hasError('pattern')"
              >
                {{ "Numbers are not allowed in First Name field" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="example-full-width my-form-field"
              style="width: 100%"
            >
              <input
                type="text"
                matInput
                autocomplete="lastName"
                formControlName="lastName"
                placeholder="{{ 'Last Name' | translate }}"
              />
              <mat-error
                *ngIf="registrationForm.get('lastName').hasError('required')"
              >
                {{ "Last Name is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="registrationForm.get('lastName').hasError('pattern')"
              >
                {{ "Numbers are not allowed in Last Name field" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="example-full-width my-form-field"
              style="width: 100%"
            >
              <mat-label>{{ "Gender" | translate }}</mat-label>
              <mat-select formControlName="gender">
                <mat-option [value]="1">{{ "Male" | translate }}</mat-option>
                <mat-option [value]="2">{{ "Female" | translate }}</mat-option>
                <mat-option [value]="3">{{ "Others" | translate }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="registrationForm.get('gender').hasError('required')"
              >
                {{ "Gender is Required." | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="example-full-width my-form-field"
              style="width: 100%"
            >
              <mat-label>{{ "Date of Birth" | translate }}</mat-label>
              <input
                readonly
                matInput
                [matDatepicker]="picker"
                [max]="maxDate"
                formControlName="dateofBrith"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error
                *ngIf="registrationForm.get('dateofBrith').hasError('required')"
              >
                {{ "Date of Birth is Required." | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="example-full-width my-form-field"
              style="width: 100%"
            >
              <input
                type="text"
                matInput
                autocomplete="about"
                formControlName="about"
                placeholder="{{ 'About Me' | translate }}"
              />
              <mat-error
                *ngIf="registrationForm.get('about').hasError('required')"
              >
                {{ "About is Required." | translate }}
              </mat-error>
            </mat-form-field>
          </mat-card>

          <mat-card style="min-height: 200px; margin-top: 10px">
            <div class="grid-container">
              <div class="grid-item">
                <p style="text-align: initial; color: #46535b">
                  {{ "Contact Information" | translate }}
                </p>
                <mat-form-field
                  class="example-full-width my-form-field"
                  style="width: 100%"
                >
                  <input
                    type="text"
                    matInput
                    autocomplete="email"
                    formControlName="email"
                    placeholder="{{ 'Email' | translate }}"
                  />
                  <mat-error
                    *ngIf="registrationForm.get('email').hasError('required')"
                  >
                    {{ "Email is Required." | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="registrationForm.get('email').hasError('pattern')"
                  >
                    {{ "Please enter a valid email" | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field
                  class="example-full-width my-form-field"
                  style="width: 25%; margin-right: 0px"
                >
                  <input
                    type="text"
                    matInput
                    autocomplete="countryCode"
                    formControlName="countryCode"
                    placeholder="{{ 'Code' | translate }}"
                    (keypress)="_keyPress($event)"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.get('countryCode').hasError('required')
                    "
                  >
                    {{ "Country Code is Required." | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.get('countryCode').hasError('maxlength')
                    "
                  >
                    {{
                      "Country Code should be maximum of 3 digits" | translate
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field
                  class="example-full-width my-form-field"
                  style="
                    width: 70%;
                    margin-right: 0px;
                    padding-left: 5%;
                    padding-top: 2%;
                  "
                >
                  <input
                    type="text"
                    matInput
                    autocomplete="phoneNumber"
                    formControlName="phoneNumber"
                    placeholder="{{ 'Phone Number' | translate }}"
                    (keypress)="_keyPress($event)"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.get('phoneNumber').hasError('required')
                    "
                  >
                    {{ "Phone Number is Required." | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.get('phoneNumber').hasError('minlength')
                    "
                  >
                    {{
                      "Phone Number should be minimum of 10 digits" | translate
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.get('phoneNumber').hasError('maxlength')
                    "
                  >
                    {{
                      "Phone Number should be maximum of 12 digits" | translate
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="grid-item">
                <p style="text-align: initial; color: #46535b">
                  {{ "Password Information" | translate }}
                </p>
                <mat-form-field
                  class="example-full-width my-form-field"
                  style="width: 100%"
                >
                  <input
                    type="password"
                    matInput
                    autocomplete="password"
                    formControlName="password"
                    placeholder="{{ 'Password' | translate }}"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm.get('password').hasError('required')
                    "
                  >
                    {{ "Password is Required." | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.get('password').hasError('minlength')
                    "
                  >
                    {{
                      "Password should be minimum of 8 characters." | translate
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.get('password').hasError('maxlength')
                    "
                  >
                    {{
                      "Password should be maximum of 15 characters." | translate
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      registrationForm.get('password').hasError('pattern') &&
                      !registrationForm.get('password').hasError('maxlength') &&
                      !registrationForm.get('password').hasError('minlength')
                    "
                  >
                    {{
                      "Please enter valid password. The Password should contain minimum 8 characters at least one numeric
                    value and at least one alphabet"
                        | translate
                    }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field
                  class="example-full-width my-form-field"
                  style="width: 100%; padding-top: 2%"
                >
                  <input
                    type="password"
                    matInput
                    autocomplete="confirmPassword"
                    formControlName="confirmPassword"
                    placeholder="{{ 'Confirm Password' | translate }}"
                  />
                  <mat-error
                    *ngIf="
                      registrationForm
                        .get('confirmPassword')
                        .hasError('required')
                    "
                  >
                    {{ "Confirm Password is Required." | translate }}
                  </mat-error>

                  <mat-error
                    *ngIf="
                      registrationForm
                        .get('confirmPassword')
                        .hasError('mustMatch')
                    "
                  >
                    {{
                      "Password and Confirm Password do not match." | translate
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="grid-item">
          <mat-card style="padding-top: 1%">
            <p style="text-align: initial; color: #46535b">
              {{ "Speciality Information" | translate }}
            </p>
            <mat-form-field
              class="example-full-width my-form-field"
              style="width: 100%"
            >
              <input
                type="text"
                matInput
                autocomplete="identificationNo"
                formControlName="identificationNo"
                placeholder="{{ 'Identification number' | translate }}"
              />
              <mat-error
                *ngIf="
                  registrationForm.get('identificationNo').hasError('required')
                "
              >
                {{ "Identification Number is Required." | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="example-full-width my-form-field"
              *ngIf="showSpeciality"
              style="width: 100%"
            >
              <mat-label>{{ "Title/Speciality" | translate }}</mat-label>
              <mat-select formControlName="speciality" multiple>
                <mat-option *ngFor="let sp of specialityList" [value]="sp">{{
                  sp.speciality[currentLanguage]
                }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="registrationForm.get('speciality').hasError('required')"
              >
                {{ "Speciality is Required." | translate }}
              </mat-error>
            </mat-form-field>

            <div>
              <mat-form-field
                class="example-full-width my-form-field left"
                [ngClass]="showOtherSpeciality ? 'semi' : 'full'"
                *ngIf="showOtherSpeciality"
              >
                <mat-label>{{ "Title/Speciality" | translate }}</mat-label>
                <mat-select formControlName="speciality" multiple>
                  <mat-option *ngFor="let sp of specialityList" [value]="sp">{{
                    sp.speciality[currentLanguage]
                  }}</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    registrationForm.get('speciality').hasError('required')
                  "
                >
                  {{ "Speciality is Required." | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field right"
                [ngClass]="showOtherSpeciality ? 'semi' : 'full'"
                *ngIf="showOtherSpeciality"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{ 'Other Speciality' | translate }}"
                  formControlName="otherSpeciality"
                />
                <mat-error
                  *ngIf="
                    registrationForm.get('otherSpeciality').hasError('required')
                  "
                >
                  {{ "Other Speciality is Required." | translate }}
                </mat-error>
                <mat-error
                  *ngIf="
                    registrationForm.get('otherSpeciality').hasError('pattern')
                  "
                >
                  {{
                    "Numbers are not allowed in other Speciality field"
                      | translate
                  }}
                </mat-error>
              </mat-form-field>
            </div>

            <mat-form-field
              class="example-full-width my-form-field"
              style="width: 100%"
              *ngIf="showsubSpeciality"
            >
              <mat-label>{{ "Sub-Speciality" | translate }}</mat-label>
              <mat-select formControlName="subspeciality" multiple>
                <mat-option *ngFor="let sub of subSpecialityList" [value]="sub"
                  >{{ sub.subSpeciality[currentLanguage] }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  registrationForm.get('subspeciality').hasError('required')
                "
              >
                {{ "Sub-Speciality is Required." | translate }}
              </mat-error>
            </mat-form-field>

            <div>
              <mat-form-field
                class="example-full-width my-form-field left"
                [ngClass]="showOtherSubSpeciality ? 'semi' : 'full'"
                *ngIf="showOtherSubSpeciality"
              >
                <mat-label>{{ "Sub-Speciality" | translate }}</mat-label>
                <mat-select formControlName="subspeciality" multiple>
                  <mat-option
                    *ngFor="let sub of subSpecialityList"
                    [value]="sub"
                    >{{ sub.subSpeciality[currentLanguage] }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    registrationForm.get('subspeciality').hasError('required')
                  "
                >
                  {{ "Sub-Speciality is Required." | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field
                class="example-full-width my-form-field right"
                [ngClass]="showOtherSubSpeciality ? 'semi' : 'full'"
                *ngIf="showOtherSubSpeciality"
              >
                <input
                  type="text"
                  matInput
                  placeholder="{{ 'Other Sub-Speciality' | translate }}"
                  formControlName="otherSubSpeciality"
                />
                <mat-error
                  *ngIf="
                    registrationForm
                      .get('otherSubSpeciality')
                      .hasError('required')
                  "
                >
                  {{ "Other SubSpeciality is Required." | translate }}
                </mat-error>
                <mat-error
                  *ngIf="
                    registrationForm
                      .get('otherSubSpeciality')
                      .hasError('pattern')
                  "
                >
                  {{
                    "Numbers are not allowed in other Sub-Speciality field"
                      | translate
                  }}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-form-field
              class="example-full-width my-form-field"
              style="width: 100%"
            >
              <input
                type="text"
                matInput
                autocomplete="cmNo"
                formControlName="cmNO"
                placeholder="{{ 'CM Number' | translate }}"
              />
              <mat-error
                *ngIf="registrationForm.get('cmNO').hasError('required')"
              >
                {{ "CM Number is Required." | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="example-full-width my-form-field"
              style="width: 100%"
            >
              <input
                type="text"
                matInput
                autocomplete="mppsNo"
                formControlName="mppsNo"
                placeholder="{{ 'MPPS Number' | translate }}"
              />
              <mat-error
                *ngIf="registrationForm.get('mppsNo').hasError('required')"
              >
                {{ "MPPS Number is Required." | translate }}
              </mat-error>
            </mat-form-field>

            <div class="sign-container">
              <mat-form-field
                class="example-full-width my-form-field profile"
                style="width: 100%"
              >
                <input
                  type="text"
                  placeholder="Firma"
                  class="fake-input"
                  matInput
                  formControlName="signature"
                  readonly
                />
                <mat-error>
                  {{ signatureError.message }}
                </mat-error>
              </mat-form-field>

              <div
                *ngIf="registrationForm.enabled"
                class="sig-container"
                #sigContainer1
                (window:resize)="beResponsive()"
                (onload)="beResponsive()"
              >
                <signature-field
                  [options]="signaturePadOptions"
                ></signature-field>
              </div>
              <button
                *ngIf="registrationForm.enabled"
                class="delete-signature"
                (click)="clear()"
              >
                Borrar
              </button>
            </div>
          </mat-card>
        </div>
        <div class="grid-item"></div>
      </div>
      <div class="register-form-remember">
        <mat-checkbox
          formControlName="privacyTerms"
          id="acceptTerms"
          color="primary"
          class="checkbox"
          [ngClass]="{
            'is-invalid':
              submitted && registrationForm.controls.privacyTerms.errors
          }"
        ></mat-checkbox>
        <label
          for="acceptTerms"
          class="form-check-label"
          (click)="$event.preventDefault()"
        >
          <span style="padding-left: 5px">{{
            "I Understand and agree to the " | translate
          }}</span>
          <a
            style="color: #65a7f6; cursor: pointer"
            (click)="$event.preventDefault(); privacy()"
            >{{ "Privacy Policy" | translate }}</a
          >
          {{ "and" | translate }}
          <a
            style="color: #65a7f6; cursor: pointer"
            (click)="$event.preventDefault(); termConditions()"
            >{{ "Terms of
            service" | translate }}</a
          ></label
        >
        <div
          *ngIf="
            submitted &&
            registrationForm.get('privacyTerms').hasError('required')
          "
          class="invalid-feedback"
        >
          {{ "Accept PrivacyPolicy & TermConditions is required" | translate }}
        </div>
      </div>
      <div class="register-button-row">
        <button type="submit" mat-raised-button>
          {{ "Continue" | translate }}
        </button>
      </div>
      <div class="register-cancel-row">
        <!-- <span class="btn4">
          <input
            type="reset"
            class="buttoncance"
            (click)="reset()"
            value="{{ 'Cancel' | translate }}"
          />
        </span> -->
        <button type="button" mat-button (click)="reset()">
          {{ "Reset" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
