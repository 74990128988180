import { Component, OnDestroy, OnInit } from "@angular/core";
import { DashboardService } from "../dashboard.service";
import { DataStoreServiceService } from "../data-store-service.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "src/app/shared/shared.service";
import { Observable, Subscription, zip } from "rxjs";
import { concatMap, map, mergeMap, take, tap } from "rxjs/operators";

export class PageParams {
  patient: string;
  status: number;
  size: number;
  skip: number;
}

@Component({
  selector: "app-new-visit-history",
  templateUrl: "./new-visit-history.component.html",
  styleUrls: ["./new-visit-history.component.scss"],
})
export class NewVisitHistoryComponent implements OnInit, OnDestroy {
  visitHistoryDetails = [];
  consultDetails: any;
  pageParams: Partial<PageParams> = { size: 3, skip: 0 };
  hideViewMore: boolean = false;
  singleVisitDetails: any;
  loading: boolean = true;
  patientId: any;
  currentLanguage: string;
  pipeLanguage: string;
  historySubscription: Subscription;

  constructor(
    private dashboardService: DashboardService,
    private store: DataStoreServiceService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private sharedService: SharedService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.visitHistoryDetails = [];
      this.pageParams.skip = 0;
      this.patientId = params.patient;
      this.getConsultationList();
    });
  }

  ngOnInit() {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
      this.pipeLanguage = this.translateService.currentLang;
    } else {
      this.currentLanguage = "es";
      this.pipeLanguage = "es-ES";
    }
    this.historySubscription = this.dashboardService.historyUpdateObservable.subscribe(
      (res: any) => {
        if (res.updated == "success") {
          this.visitHistoryDetails = [];
          this.pageParams.size =
            this.pageParams.skip === 0 ? 3 : this.pageParams.skip;
          this.pageParams.skip = 0;
          this.getConsultationList();
        }
      }
    );
  }

  getConsultationList() {
    this.pageParams.patient = this.patientId;
    this.pageParams.status = 5;

    new Observable((subscriber) => {
      subscriber.next(1);
    });

    this.dashboardService.getConsultationList(this.pageParams).subscribe(
      (res: any) => {
        console.log(" CONSULTAS", res);
        for (var i = 0; i < res.data.length; i++) {
          this.visitHistoryDetails.push(res.data[i]);
        }
        if (this.visitHistoryDetails.length == res.meta.total) {
          this.hideViewMore = true;
        } else {
          this.hideViewMore = false;
        }
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
        this.loading = false;
      }
    );
  }

  getNext() {
    this.pageParams.skip += 3;
    this.getConsultationList();
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error
      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  closeVisit(index) {
    this.visitHistoryDetails[index].opened = false;
  }

  openVisit(index) {
    this.visitHistoryDetails[index].opened = true;
  }

  ngOnDestroy() {
    if (this.historySubscription) {
      this.historySubscription.unsubscribe();
    }
  }

  haveFollowUpConsultation(visit, wich) {
    const followUpConsultation =
      wich === 1
        ? visit.followUpConsultation
        : visit[`followUpConsultation${wich}`];
    return (
      followUpConsultation &&
      followUpConsultation.length &&
      followUpConsultation[0].doctor
    );
  }

  getFollowUpDoctorName(visit, wich) {
    const followUpConsultation =
      wich === 1
        ? visit.followUpConsultation
        : visit[`followUpConsultation${wich}`];

    return this.haveFollowUpConsultation(visit, wich) &&
      followUpConsultation &&
      followUpConsultation.length &&
      followUpConsultation[0].doctor &&
      followUpConsultation[0].doctor.length &&
      followUpConsultation[0].doctor[0]?.firstName
      ? `${followUpConsultation[0].doctor[0]?.gender === 1 ? "Dr" : "Dra"}. ${
          followUpConsultation[0].doctor[0]?.firstName
        } ${followUpConsultation[0].doctor[0]?.lastName}`
      : "";
  }
}
