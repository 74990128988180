<div>
  <mat-icon style="float: right; cursor: pointer" mat-dialog-close
    >close</mat-icon
  >
  <form [formGroup]="patientForm">
    <p class="heading">
      {{
        "Please tell us the reason why you have to send the patient back to the Waiting room?"
          | translate
      }}
    </p>
    <br />
    <textarea
      class="returnarea"
      placeholder="{{ 'Enter Text....' | translate }}"
      formControlName="description"
      rows="5"
      cols="50"
      style="resize: none"
      matInput
    ></textarea>
    <div class="return-button-row">
      <button
        [disabled]="!patientForm.valid"
        mat-raised-button
        (click)="returntowaitingRoom()"
      >
        {{ "Return to the waiting room" | translate }}
      </button>
    </div>
  </form>
</div>
