import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import _isEqual from "lodash.isequal";
import { Subscription } from "rxjs";
import { VideoService } from "./video.service";
import { QbService } from "../qb.service";
import { ModalService } from "../modal.service";
import { CallfeebackComponent } from "../callfeeback/callfeeback.component";
import { MatDialog } from "@angular/material/dialog";
import { SharedService } from "src/app/shared/shared.service";
import { TranslateService } from "@ngx-translate/core";
import { UserIdleService } from "angular-user-idle";

declare var QB: any;

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
})
export class VideoComponent implements OnInit, OnDestroy {
  @Input() userData: any;
  @Input() consultId: string;
  qbSessionSubscription: Subscription;
  onStopCallSubscription: Subscription;
  onSessionCloseSubscription: Subscription;
  onRejectCallSubscription: Subscription;
  onAcceptCallSubscription: Subscription;
  onUserNotAnswerSubscription: Subscription;
  onRemoteStreamSubscription: Subscription;
  QBSession: any;
  qbConnectionSubscriptions: Subscription[] = [];
  muteStream: boolean = false;
  muteSound: boolean = false;
  fullScreenDisplay: boolean = false;
  hours: string;
  minutes: string;
  seconds: string;
  currentTime: number;
  connected: boolean = false;
  mediaParams = {
    audio: true,
    video: true,
    options: {
      muted: true,
      mirror: false,
    },
    elemId: "localVideo",
  };
  timerResult: any;
  extension = {};
  private localStream: MediaStream;

  constructor(
    private videoService: VideoService,
    private qbService: QbService,
    private modalService: ModalService,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public translationService: TranslateService,
    private userIdle: UserIdleService
  ) {}

  ngOnInit(): void {
    this.configureSettings();
    this.makeCall();
  }

  // configuration settings for all the events that takes place for the whole session
  configureSettings() {
    this.qbSessionSubscription = this.videoService.sessionObservable.subscribe(
      (res) => {
        this.QBSession = res;
        if (Object.keys(res).length > 0) {
          this.getUserMedia();
        }
      }
    );

    // subscribing to all the listener events

    this.onRejectCallSubscription = this.qbService.rejectCallObservable.subscribe(
      (data) => {
        this.translationService
          .get("User rejected the call")
          .subscribe((text: string) => {
            this.sharedService.showErrorMessage(text, "single");
          });
      }
    );

    this.onStopCallSubscription = this.qbService.stopCallObservable.subscribe(
      (data) => {
        this.translationService
          .get("User disconnected the call")
          .subscribe((text: string) => {
            this.sharedService.showErrorMessage(text, "single");
          });
      }
    );

    this.onSessionCloseSubscription = this.qbService.sessionCloseObservable.subscribe(
      (data) => {
        this.videoService.stopRecording(true);
        this.userIdle.startWatching();
        this.localStream = null;
        this.closeFullscreen();
        this.callFeedbackPopup();
        this.modalService.disconnectCallSubject.next({
          callStatus: "disconnected",
        });
      }
    );

    this.onAcceptCallSubscription = this.qbService.acceptCallObservable.subscribe(
      (data) => {}
    );

    this.onRemoteStreamSubscription = this.qbService.remoteStreamObservable.subscribe(
      (data) => {
        const remoteStream: MediaStream = data.stream;

        this.QBSession.attachMediaStream("remoteVideo", remoteStream);
        this.connected = true;
        this.modalService.callStatus({
          callStarted: true,
        });
        this.videoService.startRecording(
          this.consultId,
          this.localStream,
          remoteStream
        );
        this.userIdle.stopWatching();
      }
    );

    this.onUserNotAnswerSubscription = this.qbService.userNotAnswerObservable.subscribe(
      (data) => {
        this.translationService
          .get("User not answered the call")
          .subscribe((text: string) => {
            this.sharedService.showErrorMessage(text, "single");
          });
      }
    );

    this.qbConnectionSubscriptions.push(this.onUserNotAnswerSubscription);
    this.qbConnectionSubscriptions.push(this.onRemoteStreamSubscription);
    this.qbConnectionSubscriptions.push(this.onRejectCallSubscription);
    this.qbConnectionSubscriptions.push(this.onStopCallSubscription);
    this.qbConnectionSubscriptions.push(this.onAcceptCallSubscription);
    this.qbConnectionSubscriptions.push(this.onSessionCloseSubscription);
  }

  callFeedbackPopup() {
    const dialogRef = this.dialog.open(CallfeebackComponent, {
      width: "418px",
      disableClose: true,
      autoFocus: false,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  // initiating  call
  makeCall() {
    let qbUserId;
    if (this.userData.patient.parent) {
      qbUserId = this.userData.patient.parent.quickbloxId;
    } else {
      qbUserId = this.userData.patient.quickbloxId;
    }
    var calleeId = [qbUserId];
    var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
    var callerID = null; // Your user ID (optional, will be defined from chat connection)
    var additionalOptions = { bandwith: 512 }; // The video bandwith (number, optional, 0 = unlimited)
    this.videoService.createSession(
      calleeId,
      sessionType,
      callerID,
      additionalOptions
    );
  }

  // Get local media stream
  getUserMedia() {
    this.QBSession.getUserMedia(this.mediaParams, (err: any, stream: any) => {
      if (err) {
      } else {
        this.localStream = stream;
        this.makeCallRequest();
      }
    });
  }

  // Making call request
  makeCallRequest() {
    this.QBSession.call(this.extension, (error: any) => {
      if (error) {
      } else {
      }
    });
  }

  disConnect(): void {
    var extension = {};
    this.QBSession.stop(extension);
  }

  muteVideo() {
    this.QBSession.mute("video");
    this.muteStream = true;
  }

  muteMike() {
    this.QBSession.mute("audio");
    this.muteSound = true;
  }

  unMuteVideo() {
    this.QBSession.unmute("video");
    this.muteStream = false;
  }

  unMuteMike() {
    this.QBSession.unmute("audio");
    this.muteSound = false;
  }

  openFullscreen() {
    var elem = document.getElementById("video-container");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      this.fullScreenDisplay = true;
    }
  }

  setCurrentTime(data) {
    this.currentTime = Math.floor(data.target.currentTime);

    var hrs = Math.floor(this.currentTime / 3600);
    var min = Math.floor((this.currentTime - hrs * 3600) / 60);
    var seconds = this.currentTime - hrs * 3600 - min * 60;
    seconds = Math.round(seconds * 100) / 100;

    this.timerResult = hrs < 10 ? "0" + hrs : hrs;
    this.timerResult += ":" + (min < 10 ? "0" + min : min);
    this.timerResult += ":" + (seconds < 10 ? "0" + seconds : seconds);
  }

  pad(val) {
    var valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }

  closeFullscreen() {
    this.fullScreenDisplay = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  ngOnDestroy() {
    if (this.QBSession != undefined) {
      var extension = {};
      this.QBSession.stop(extension);
    }
    this.qbConnectionSubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );

    if (this.qbSessionSubscription) {
      this.qbSessionSubscription.unsubscribe();
    }
  }
}
