import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder, NgForm } from "@angular/forms";
import { RegistrationService } from "./registration.service";
import passwordHash from "js-sha512";
import { Router } from "@angular/router";
import { CredentialsService } from "../core/credentials.service";
import { PrivacypolicyComponent } from "../shared/privacypolicy/privacypolicy.component";
import { TermsConditionComponent } from "../shared/terms-condition/terms-condition.component";
import { MatDialog } from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../shared/shared.service";
import { MustMatch } from "../dashboard/change-password/must-match.validator";
import moment from "moment";
import { SignatureFieldComponent } from "../shared/signature-field/signature-page.component";
import { concat } from "rxjs";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationComponent implements OnInit, AfterViewInit {
  registrationForm: FormGroup;
  specialityList: [];
  subSpecialityList: [];
  showSpeciality: boolean = true;
  showsubSpeciality: boolean = true;
  showOtherSpeciality: boolean = false;
  showOtherSubSpeciality: boolean = false;
  doctorInformation: any;
  hashedPassword: any;
  submitted: boolean = false;
  maxDate: any;
  currentLanguage = "es";
  nameRegex = /^([^0-9]*)$/;

  @ViewChild("form") formElement: ElementRef;

  @ViewChild(SignatureFieldComponent) public sig1: SignatureFieldComponent;
  @ViewChild("sigContainer1") public sigContainer1: ElementRef;

  signatureError = {
    message: "La Firma es requerida",
    status: false,
  };

  public signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    canvasHeight: 125,
  };

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private credentialsService: CredentialsService,
    private doctorService: RegistrationService,
    private translationService: TranslateService,
    private sharedService: SharedService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.maxDate = moment().subtract(18, "y").format("YYYY-MM-DD");

    if (this.translationService.currentLang == "en-US") {
      this.currentLanguage = "en";
    } else {
      this.currentLanguage = "es";
    }
    this.getSpecialitiesList();
    this.getSubSpecialityList();
    let nameRegex = /^([^0-9]*)$/;
    let passwordregex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,15}$/;
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.registrationForm = this.fb.group(
      {
        firstName: ["", [Validators.required, Validators.pattern(nameRegex)]],
        lastName: ["", [Validators.required, Validators.pattern(nameRegex)]],
        gender: ["", Validators.required],
        dateofBrith: ["", Validators.required],
        about: [""],
        identificationNo: ["", Validators.required],
        speciality: ["", Validators.required],
        subspeciality: [""],
        cmNO: ["", Validators.required],
        mppsNo: ["", Validators.required],
        email: ["", [Validators.required, Validators.pattern(emailregex)]],
        countryCode: ["", [Validators.required, Validators.maxLength(3)]],
        phoneNumber: [
          "",
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(16),
          ],
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(passwordregex),
            Validators.minLength(8),
            Validators.maxLength(15),
          ],
        ],
        confirmPassword: ["", [Validators.required]],
        privacyTerms: ["", Validators.requiredTrue],
        otherSubSpeciality: [""],
        otherSpeciality: [""],
        signature: ["", Validators.required],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
    this.otherSpeciality();
    this.otherSubSpeciality();
    this.registrationForm.patchValue({
      firstName: localStorage.getItem("registerFirstName"),
      lastName: localStorage.getItem("registerLastName"),
      email: localStorage.getItem("registerEmail"),
    });
  }

  ngAfterViewInit() {
    this.beResponsive();
    this.sig1.registerOnChange((signature) => {
      if (!signature) {
        this.registrationForm.controls["signature"].setValue("");
        this.registrationForm.controls["signature"].setErrors({
          required: true,
        });
      } else {
        this.registrationForm.controls["signature"].clearValidators();
        this.registrationForm.controls["signature"].setValue("");
      }

      this.registrationForm.updateValueAndValidity();
    });
  }

  getSpecialitiesList() {
    let defaultSelection;
    this.doctorService.getSpecialitiesList().subscribe((res: any) => {
      this.specialityList = res.data;
      defaultSelection = res.data.filter((o) =>
        o.speciality.en.includes("Surgeon")
      );
      this.registrationForm.patchValue({
        speciality: defaultSelection,
      });
    });
  }

  getSubSpecialityList() {
    this.doctorService.getSubSpecialitiesList().subscribe((res: any) => {
      this.subSpecialityList = res.data;
    });
  }

  createDoctor() {
    this.submitted = true;
    if (!this.sig1.signature) {
      this.signatureError.status = true;
      return;
    } else {
      this.signatureError.status = false;
    }

    if (!this.registrationForm.valid) {
      return;
    }

    this.doctorInformation = this.registrationForm.value;
    if (
      this.registrationForm.value.password ==
      this.registrationForm.value.confirmPassword
    ) {
      this.hashedPassword = passwordHash.sha512(
        this.registrationForm.value.password
      );
    }

    let data = {
      firstName: this.doctorInformation.firstName,
      lastName: this.doctorInformation.lastName,
      gender: this.doctorInformation.gender,
      dateOfBirth: this.doctorInformation.dateofBrith,
      mppsNumber: this.doctorInformation.mppsNo,
      cmNumber: this.doctorInformation.cmNO,
      about: this.doctorInformation.about || "",
      identificationNumber: this.doctorInformation.identificationNo,
      phoneNumber: this.doctorInformation.phoneNumber,
      countryCode: this.doctorInformation.countryCode,
      speciality: this.doctorInformation.speciality.map((a: any) => a._id),
      subSpeciality:
        this.doctorInformation.subspeciality &&
        this.doctorInformation.subspeciality.length > 0
          ? this.doctorInformation.subspeciality.map((b: any) => b._id)
          : [],
      password: this.hashedPassword,
    };

    this.ref.detectChanges();

    const form = new FormData();
    form.append(
      "file",
      new File(
        [this.dataURItoBlob(this.sig1.signaturePad.toDataURL("png"))],
        "signature.csv"
      )
    );

    concat(
      this.doctorService.addDoctors(data),
      this.doctorService.updateFirmPic(form)
    ).subscribe(
      (res: any) => {
        this.translationService
          .get("Profile has been created Successfully")
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });
        this.router.navigate([""]);
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
  otherSpeciality() {
    var tempOtherSpecitality = this.registrationForm.get("otherSpeciality");
    this.registrationForm.get("speciality").valueChanges.subscribe((val) => {
      const checkOtherFieldExistence = val.some(function (el) {
        return el.isOther === true;
      });

      if (checkOtherFieldExistence) {
        this.showOtherSpeciality = true;
        this.showSpeciality = false;
        tempOtherSpecitality.setValidators([
          Validators.required,
          Validators.pattern(this.nameRegex),
        ]);
        tempOtherSpecitality.markAsDirty();
        tempOtherSpecitality.markAsTouched();
      } else {
        this.showSpeciality = true;
        this.showOtherSpeciality = false;
        tempOtherSpecitality.clearValidators();
      }
    });
  }

  otherSubSpeciality() {
    var tempOtherSubSpecitality = this.registrationForm.get(
      "otherSubSpeciality"
    );
    this.registrationForm.get("subspeciality").valueChanges.subscribe((val) => {
      const checkOtherFieldExistence = val.some(function (el) {
        return el.isOther === true;
      });

      if (checkOtherFieldExistence) {
        this.showOtherSubSpeciality = true;
        this.showsubSpeciality = false;
        tempOtherSubSpecitality.setValidators([
          Validators.required,
          Validators.pattern(this.nameRegex),
        ]);
        tempOtherSubSpecitality.markAsDirty();
        tempOtherSubSpecitality.markAsTouched();
      } else {
        this.showOtherSubSpeciality = false;
        this.showsubSpeciality = true;
        tempOtherSubSpecitality.clearValidators();
      }
    });
  }

  privacy() {
    const dialogRef = this.dialog.open(PrivacypolicyComponent, {
      width: "752px",
      maxHeight: "500px",
      disableClose: true,
      autoFocus: false,
      // height:'165px'
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  termConditions() {
    const dialogRef = this.dialog.open(TermsConditionComponent, {
      width: "752px",
      maxHeight: "500px",
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  reset() {
    this.submitted = false;
    this.registrationForm.reset({
      firstName: localStorage.getItem("registerFirstName"),
      lastName: localStorage.getItem("registerLastName"),
      email: localStorage.getItem("registerEmail"),
    });
  }

  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public beResponsive() {
    this.ref.detectChanges();
    this.size(this.sigContainer1, this.sig1);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    // this.sigContainer1.nativeElement.style.width = 100;
    console.log("container", container);
    sig.signaturePad["signaturePad"]._canvas.width =
      container.nativeElement.clientWidth;
  }

  public setOptions() {
    this.sig1.signaturePad.set("penColor", "rgb(0, 0, 0)");
  }

  public clear() {
    this.sig1.clear();
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });
  }
}
