import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

class DoctorCommunication {
  audio: boolean;
  chat: boolean;
  video: boolean;
}

@Injectable()
export class CallStateService {
  private callState = new BehaviorSubject<any>(false);

  cast = this.callState.asObservable();

  updateCallState(data: boolean) {
    this.callState.next(data);
  }
}
