import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { SubscriptionDetails } from "../core/subscriptions.spec";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private http: HttpClient) {}

  /**
   * status - 0 - call not started
   * status - 1 - call inprogress
   * status - 2 - call completed
   */
  public callFeedBackSubject = new Subject<any>();
  callFeedBackObservable = this.callFeedBackSubject.asObservable();

  /**
   * status - 2 - video call
   * status - 1 - audio call
   * status - 3 - chat
   */
  public reconnectSubject = new Subject<any>();
  reconnectObservable = this.reconnectSubject.asObservable();

  public disconnectCallSubject = new Subject<any>();
  disconnectCallObservable = this.disconnectCallSubject.asObservable();

  public vsSubmitSubject = new Subject<any>();
  vsSubmitObservable = this.vsSubmitSubject.asObservable();
  sendVsFormData(value: any) {
    this.vsSubmitSubject.next(value);
  }

  public vscallStatus = new Subject<any>();
  vscallStatusObservable = this.vscallStatus.asObservable();
  callStatus(value: any) {
    this.vscallStatus.next(value);
  }

  /**
   * Get user subscriptions.
   * @return The user subscriptions array.
   */
  getSubscriptions(patientId: string): Observable<SubscriptionDetails[]> {
    let data = { patient: patientId };
    return this.http
      .get<any>(`/subscriptions/list-all-subscription?patient=${patientId}`)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }
}
