<div class="reconnect-container">
  <mat-icon style="float: right; cursor: pointer" mat-dialog-close
    >close</mat-icon
  >
  <h5 class="heading">
    {{ "How do you want to reconnect the call ?" | translate }}
  </h5>
  <br /><br />
  <mat-list role="list">
    <mat-radio-group [(ngModel)]="selection" #radioGroup="matRadioGroup">
      <mat-list-item role="listitem">
        <mat-card class="reconnectCall">
          <mat-radio-button
            value="2"
            color="primary"
            [labelPosition]="'before'"
          >
            <span class="subheading">{{ "Video Call" | translate }}</span
            ><br /><img
              src="../../../assets/images/video.svg"
              style="margin-right: 6%"
            /><span class="paragraph"
              >{{ "Video allows you to have a call" | translate }}<br />{{
                "through a video call" | translate
              }}</span
            >
          </mat-radio-button>
        </mat-card>
      </mat-list-item>
      <br /><br /><br /><br />
      <mat-list-item role="listitem">
        <mat-card class="reconnectCall">
          <mat-radio-button
            value="1"
            color="primary"
            [labelPosition]="'before'"
          >
            <span class="subheading">{{ "Phone Call" | translate }}</span
            ><br /><img
              src="../../../assets/images/call.svg"
              style="margin-right: 6%"
            /><span class="paragraph"
              >{{ "Phone allows you to have a call" | translate }}<br />{{
                "through a phone call" | translate
              }}</span
            >
          </mat-radio-button>
        </mat-card>
      </mat-list-item>
      <!-- <br /><br /><br /><br />
      <mat-list-item role="listitem">
        <mat-card class="reconnectCall">
          <mat-radio-button
            value="3"
            color="primary"
            [labelPosition]="'before'"
          >
            <span class="subheading">{{ "Chat" | translate }}</span
            ><br /><img
              src="../../../assets/images/msg.svg"
              style="margin-right: 6%"
            /><span class="paragraph"
              >{{ "Chat allows you to have a chat" | translate }}<br />{{
                "through a chat" | translate
              }}</span
            >
          </mat-radio-button>
        </mat-card>
      </mat-list-item> -->
    </mat-radio-group>
  </mat-list>
  <div class="reconnect-button-row">
    <button mat-raised-button (click)="getReconnectValue()">
      {{ "Connect" | translate }}
    </button>
  </div>
</div>
