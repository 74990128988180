import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  bookDetails = [
    { id: 101, name: "Angular by Krishna", category: "Angular" },
    { id: 102, name: "Core Java by Vishnu", category: "Java" },
    { id: 103, name: "NgRx by Rama", category: "Angular" },
    { id: 104, name: "Rk by Raja", category: "Angular7" },
  ];
  constructor(private http: HttpClient) {}
  /**
   * Assign params with key value pare
   * @param params: any
   */
  private getHttpParams(params) {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        httpParams = httpParams.set(key, element);
      }
    }
    return httpParams;
  }

  searchingValue(param) {
    const httpParams = this.getHttpParams(param);
    return this.http.get(`/patients/`, { params: httpParams });
  }
}
