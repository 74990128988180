import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeHeaderComponent } from "./home-header/home-header.component";
import { MaterialModule } from "../material.module";
import { AvatarModule } from "ngx-avatar";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { TranslateModule } from "@ngx-translate/core";
import { ErrormessageComponent } from "./errormessage/errormessage.component";
import { MultipleerrormessageComponent } from "./multipleerrormessage/multipleerrormessage.component";
import { RouterModule } from "@angular/router";
import { TextAreaComponent } from "./text-area/text-area.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { OrderByPipe } from "./order-by/order-by.pipe";
import { DragAndDropFileDirective } from "./drag-and-drop-file/drag-and-drop-file.directive";
@NgModule({
  declarations: [
    HomeHeaderComponent,
    PrivacypolicyComponent,
    TermsConditionComponent,
    ErrormessageComponent,
    MultipleerrormessageComponent,
    TextAreaComponent,
    OrderByPipe,
    DragAndDropFileDirective,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AvatarModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
  ],
  exports: [
    HomeHeaderComponent,
    TextAreaComponent,
    OrderByPipe,
    DragAndDropFileDirective,
  ],
})
export class SharedModule {}
