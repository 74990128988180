import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();
    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    if (this.translateService.currentLang == "en-US") {
      this.nextPageLabel = "Next Page";
      this.previousPageLabel = "Previous page";
    } else {
      this.nextPageLabel = "Siguiente página";
      this.previousPageLabel = "Pagina anterior";
    }
    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  };
}
