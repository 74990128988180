import { Injectable, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class QbService {
  constructor(private http: HttpClient) {}

  _notificationObj = new EventEmitter<any>();

  // Quickblox observables list starts here //
  public remoteStreamSubject = new Subject<any>();
  remoteStreamObservable = this.remoteStreamSubject.asObservable();

  public stopCallSubject = new Subject<any>();
  stopCallObservable = this.stopCallSubject.asObservable();

  public rejectCallSubject = new Subject<any>();
  rejectCallObservable = this.rejectCallSubject.asObservable();

  public sessionCloseSubject = new Subject<any>();
  sessionCloseObservable = this.sessionCloseSubject.asObservable();

  public acceptCallSubject = new Subject<any>();
  acceptCallObservable = this.acceptCallSubject.asObservable();

  public userNotAnswewrSubject = new Subject<any>();
  userNotAnswerObservable = this.userNotAnswewrSubject.asObservable();

  // Quickblox observables list ends here //

  public qbListenerSubject = new Subject<any>();
  qbListenerObservable = this.qbListenerSubject.asObservable();

  /*
   * chat
   */
  public disconnectedSubject = new Subject<any>();
  disconnectedObservable = this.disconnectedSubject.asObservable();

  public reconnectChatSubject = new Subject<any>();
  reconnectChatObservable = this.reconnectChatSubject.asObservable();

  public messageSubject = new Subject<any>();
  messageObservable = this.messageSubject.asObservable();

  // OnUserNotAnswerListener Event
  onUserNotAnswerListener = (session, userId) => {
    let status = {
      userId: userId,
      session: session,
    };
    this.userNotAnswewrSubject.next(status);
  };

  // OnRemoteStreamListener Event
  onRemoteStreamListener = (session, userId, stream) => {
    let remoteStreamObj = {
      session: session,
      userId: userId,
      stream: stream,
    };
    this.remoteStreamSubject.next(remoteStreamObj);
  };

  // OnAcceptCallListener event
  onAcceptCallListener = (session, userId, extension) => {
    let acceptObj = {
      session: session,
      userId: userId,
      extension: extension,
    };
    this.acceptCallSubject.next(acceptObj);
  };

  onSessionCloseListener = (session) => {
    this.sessionCloseSubject.next(session);
  };

  onRejectCallListener = (session, userId, extension) => {
    let rejectObj = {
      session: session,
      userId: userId,
      extension: extension,
    };
    this.rejectCallSubject.next(rejectObj);
  };

  onStopCallListener = (session, userId, extension) => {
    let stopCallObj = {
      session: session,
      userId: userId,
      extension: extension,
    };
    this.stopCallSubject.next(stopCallObj);
  };

  qbListener(value: boolean) {
    this.qbListenerSubject.next(value);
  }

  onDisconnectedListener = (session, userId) => {
    let disconnectedObj = {
      userId: userId,
      session: session,
    };
    this.disconnectedSubject.next(disconnectedObj);
  };

  onReconnectListener = (session, userId) => {
    let reconnectObj = {
      userId: userId,
      session: session,
    };
    this.reconnectChatSubject.next(reconnectObj);
  };

  onMessageListener = (userId, message) => {
    let msgObj = {
      userId: userId,
      message: message,
    };
    this.messageSubject.next(msgObj);
  };

  retriveMessage(token, dialogId) {
    return this.http.get(
      "https://api.quickblox.com/chat/Message.json?chat_dialog_id=" + dialogId,
      {
        headers: { "QB-Token": token },
      }
    );
  }
}
