<!-- <div class="example-boundary"> -->
<div class="example-box" cdkDragLockAxis="x" cdkDrag>
  <div class="example-handle"></div>
  <div id="video-container">
    <video id="localVideo" #localVideo></video>

    <video
      id="remoteVideo"
      #remoteVideo
      (timeupdate)="setCurrentTime($event)"
      [style.object-fit]="fullScreenDisplay ? 'contain' : 'cover'"
    ></video>

    <div
      style="
        position: absolute;
        min-height: 35px;
        top: 0px;
        z-index: 100;
        width: 100%;
        color: white;
        font-weight: bold;
        background: #46535b00;
        box-shadow: -2px 4px 22px rgba(0, 0, 0, 0.26);
      "
    >
      <span style="top: 10px; margin-left: 10px; position: absolute">{{
        "Video Call" | translate
      }}</span>
      <span
        style="margin-left: 120px; top: 10px; position: absolute"
        *ngIf="connected"
        >{{ timerResult }}</span
      >
      <img
        style="
          position: relative;
          float: right;
          cursor: pointer;
          right: 5px;
          top: 5px;
          width: 25px;
        "
        src="../../../assets/images/zoomin.svg"
        class="action-btn"
        [ngClass]="fullScreenDisplay ? 'zoom-out' : 'zoom-in'"
        (click)="openFullscreen()"
        *ngIf="!fullScreenDisplay"
      />
      <img
        style="
          position: relative;
          cursor: pointer;
          float: right;
          right: 5px;
          top: 5px;
          width: 25px;
        "
        src="../../../assets/images/zoomout.svg"
        class="action-btn"
        [ngClass]="fullScreenDisplay ? 'zoom-out' : 'zoom-in'"
        (click)="closeFullscreen()"
        *ngIf="fullScreenDisplay"
      />
      <!-- <span>{{currentTime}}</span> -->
    </div>

    <div
      style="
        text-align: center;
        z-index: 100;
        position: absolute;
        bottom: 20px;
        width: 100%;
      "
    >
      <img
        src="../../assets/images/unmute-mike.svg"
        class="action-btn"
        [ngClass]="fullScreenDisplay ? 'fullScreen' : 'normalScreen'"
        (click)="muteMike()"
        *ngIf="!muteSound"
      />
      <img
        src="../../../assets/images/mute-mike.svg"
        class="action-btn"
        [ngClass]="fullScreenDisplay ? 'fullScreen' : 'normalScreen'"
        (click)="unMuteMike()"
        *ngIf="muteSound"
      />
      <img
        src="../../../assets/images/end.svg"
        (click)="disConnect()"
        [ngClass]="fullScreenDisplay ? 'fullScreen' : 'normalScreen'"
        class="action-btn"
      />
      <img
        src="../../../assets/images/turn-on-video.svg"
        class="action-btn"
        [ngClass]="fullScreenDisplay ? 'fullScreen' : 'normalScreen'"
        (click)="muteVideo()"
        *ngIf="!muteStream"
      />
      <img
        src="../../assets/images/turn-off-video.svg"
        class="action-btn"
        [ngClass]="fullScreenDisplay ? 'fullScreen' : 'normalScreen'"
        (click)="unMuteVideo()"
        *ngIf="muteStream"
      />
      <!-- <img src="../../assets/pip.png" width="20px" class="action-btn"> -->
    </div>
  </div>
</div>
<!-- </div> -->
