<div class="flex">
  <div class="data">
    <img
      src="../../../assets/images/close.svg"
      style="margin-right: 2%"
      (click)="dismiss()"
    />
    {{ data }}
  </div>
</div>
