<div class="container" *ngIf="!loading">
  <h5 class="heading">{{ "Medication" | translate }}</h5>
  <mat-accordion>
    <mat-expansion-panel
      class="mat-elevation-z0"
      *ngFor="let panel of panels; let i = index; first as isFirst"
      [expanded]="isFirst"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>{{ panel.title | translate }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div
        style="text-align: center"
        *ngIf="!panel.medicine || panel.medicine.length === 0"
      >
        <img
          *ngIf="currentLanguage == 'en'"
          style="display: block; margin: 0 auto"
          src="../../../assets/images/no-medication-en.svg"
        />
        <img
          *ngIf="currentLanguage == 'es'"
          style="display: block; margin: 0 auto"
          src="../../../assets/images/no-medication-es.svg"
        />
      </div>

      <div *ngFor="let medicine of panel.medicine">
        <mat-card style="border-radius: 12px">
          <!-- <div class="left-container"> -->
          <div class="medication-container">
            <div style="width: 100%">
              <mat-form-field
                appearance="fill"
                style="width: 50%; position: relative"
                class="medicationOption"
              >
                <mat-select
                  style="min-width: 100%"
                  [disabled]="disableButton"
                  [(ngModel)]="medicine.selected"
                  matTooltip="{{ medicine.selected.name }}"
                  placeholder="{{ 'Select your option' | translate }}"
                  (selectionChange)="changeMedicine($event, panel.title)"
                >
                  <mat-option
                    *ngFor="let option of medicine.alternative"
                    [value]="option"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span
                *ngIf="medicine.alternative.length > 1"
                style="
                  margin-left: 2%;
                  font-style: normal;
                  font-weight: bold;
                  color: #92a0a5;
                  font-size: 0.688rem;
                "
                >{{
                  medicine.alternative.length -
                    1 +
                    " " +
                    ("alternatives suggested" | translate)
                }}</span
              >
              <span style="float: right">
                <button
                  *ngIf="!disableButton"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <mat-icon>reorder</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    *ngIf="panel.title != 'Currently Taking'"
                    (click)="changeToCurrentlyTaking(medicine.selected._id, i)"
                  >
                    <span>{{ "Currently Taking" | translate }}</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="panel.title != 'Not Taking'"
                    (click)="
                      changeToCurrentlyNotTaking(medicine.selected._id, i)
                    "
                  >
                    <span>{{ "Not Taking" | translate }}</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="panel.title != 'Completed'"
                    (click)="changeToCompleted(medicine.selected._id, i)"
                  >
                    <span>{{ "Completed" | translate }}</span>
                  </button>
                  <button
                    *ngIf="
                      medicine.doctor && medicine.doctor._id == currentUserId
                    "
                    mat-menu-item
                    (click)="deleteMedicine(medicine.selected._id, i)"
                  >
                    <span style="color: #ee2029">{{
                      "Delete" | translate
                    }}</span>
                  </button>
                </mat-menu>
              </span>
            </div>
            <div class="medication-field">
              <h3 class="user-details-heading">
                {{ "Indications" | translate }}
              </h3>
              <div class="field-value">
                <span
                  *ngIf="
                    medicine.selected.doctor &&
                      medicine.selected.frequency &&
                      medicine.selected.frequencyTime &&
                      medicine.selected.duration &&
                      medicine.selected.durationTime;
                    else otherIndication
                  "
                >
                  {{
                    (medicine.selected.dosage ? medicine.selected.dosage : "") +
                      " " +
                      (medicine.selected.unit
                        ? medicine.selected.unit.unit[currentLanguage]
                        : "") +
                      " " +
                      (medicine.selected.unit ? ("via" | translate) : "") +
                      " " +
                      (medicine.selected.routeOfAdministration
                        ? medicine.selected.routeOfAdministration
                            .routeOfAdministration[currentLanguage]
                        : "") +
                      " " +
                      ("every" | translate) +
                      " " +
                      medicine.selected.frequency +
                      " " +
                      medicine.selected.frequencyTime.time[currentLanguage] +
                      " " +
                      ("for" | translate) +
                      " " +
                      medicine.selected.duration +
                      " " +
                      medicine.selected.durationTime.time[currentLanguage]
                  }}
                </span>
                <ng-template #otherIndication>
                  {{
                    medicine.selected.indications
                      ? medicine.selected.indications
                      : "-"
                  }}
                </ng-template>
              </div>
            </div>

            <div class="medication-field">
              <h3 class="user-details-heading">
                {{ "Date" | translate }}
              </h3>
              <div class="field-value">
                {{
                  medicine.selected.updatedAt
                    | date: "longDate":"+0:00":pipeLanguage
                }}
              </div>
            </div>
            <!-- </div> -->
            <br /><br />
            <!-- <div class="right-container"> -->
            <div class="medication-field" style="padding-top: 3%">
              <h3 class="user-details-heading">
                {{ "Notes" | translate }}
              </h3>
              <div class="field-value">
                <span *ngIf="medicine.selected.notes; else otherNotes">
                  {{ medicine.selected.notes }}
                </span>
                <ng-template #otherNotes> - </ng-template>
              </div>
            </div>

            <div class="medication-field" style="padding-top: 3%">
              <h3 class="user-details-heading">
                {{ "Doctor" | translate }}
              </h3>
              <div class="field-value">
                <span *ngIf="medicine.doctor != undefined; else name">
                  {{
                    medicine.doctor.firstName + " " + medicine.doctor.lastName
                  }}
                  (MPPS:{{ medicine.doctor.mppsNumber }})
                  <br />
                  {{
                    medicine.doctor.speciality[0].speciality[currentLanguage]
                  }}
                </span>
                <ng-template #name> - </ng-template>
              </div>
            </div>
            <span
              style="
                font-style: normal;
                color: #92a0a5;
                font-size: 0.788rem;
                text-align: end;
                width: 100%;
              "
            >
              <div class="dot"></div>
              {{ "Assigned by" | translate }}
              {{
                medicine.consultation && medicine.consultation.consultationType
                  ? ("Follow Up" | translate) +
                    " " +
                    medicine.consultation.consultationType
                  : ("Main Consultation" | translate)
              }}
            </span>
          </div>
          <!-- </div> -->
        </mat-card>
        <br />
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
