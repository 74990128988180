import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataStoreServiceService {
  private patientRecord: Subject<any>;

  constructor() {
    this.patientRecord = new Subject<any>();
  }

  public getPatientRecord(): Observable<any> {
    return this.patientRecord.asObservable();
  }

  public setPatientRecord(newName: any): void {
    this.patientRecord.next(newName);
  }
}
