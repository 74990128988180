import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class RegistrationService {
  constructor(private http: HttpClient) {}

  getSpecialitiesList() {
    return this.http.get(`/specialities`);
  }

  getSubSpecialitiesList() {
    return this.http.get(`/sub-specialities`);
  }
  getDoctors() {
    return this.http.get(`/doctors/me`);
  }
  addDoctors(formValues: any) {
    return this.http.put(`/doctors/register`, formValues);
  }
  editDoctors(formValues: any) {
    return this.http.put(`/doctors/me`, formValues);
  }
  updateProfilePic(data: any) {
    return this.http.patch(`/doctors/profile-pic`, data);
  }
  updateFirmPic(data: any) {
    return this.http.patch(`/doctors/firm-pic`, data);
  }
  getTermsCondition() {
    return this.http.get<any>(`/agreements/2?agreementType=1`);
  }
  getPrivacyPolicy() {
    return this.http.get<any>(`/agreements/2?agreementType=2`);
  }
}
