<mat-panel-title>
  <hr style="color: #efefef; opacity: 0.5" *ngIf="!pos" />
  <div
    style="height: 4.5em; display: flex"
    [style.height]="pos ? '5.5em' : '4.5em'"
  >
    <div style="height: 100%; flex: 0.03">
      <div>
        <div
          [class]="doctorName ? 'line mbottom' : 'line2 mbottom'"
          [class.large]="pos"
        ></div>
        <div class="dot"></div>
        <div
          *ngIf="pos !== '3' && hasNext"
          [class]="doctorName ? 'line mtop' : 'line2 mtop'"
        ></div>
      </div>
    </div>
    <div style="height: 100%; flex: 1">
      <hr style="color: #efefef; opacity: 0.5" *ngIf="pos" />
      <span class="chiefCompliant" style="color: #020047">
        {{ "Follow up" | translate }} {{ pos || "1" }}
      </span>
      <div *ngIf="doctorName" class="consultDate">
        {{ doctorName }}
      </div>
      <div class="consultDate">
        {{ date | titlecase }}
      </div>
    </div>
  </div>
</mat-panel-title>
