<div class="forgot-container">
  <app-header></app-header>
  <div class="container">
    <div style="text-align: center">
      <h4 class="heading">{{ "Forgot Password" | translate }}</h4>
      <p class="subheading">
        {{ "Please enter your registered email below" | translate }}
      </p>
    </div>
    <mat-card class="forgot-card">
      <form
        class="example-form"
        [formGroup]="forgotpasswordForm"
        (ngSubmit)="forgotPasswordSubmit()"
      >
        <p style="text-align: initial; color: #46535b; font-weight: 600">
          {{ "We will send you link to reset your password" | translate }}
        </p>
        <mat-form-field
          class="example-full-width my-form-field"
          style="width: 100%"
        >
          <input
            type="text"
            matInput
            autocomplete="email"
            placeholder="{{ 'Email' | translate }}"
            formControlName="email"
          />
          <mat-error
            *ngIf="forgotpasswordForm.get('email').hasError('required')"
          >
            {{ "Email is Required." | translate }}
          </mat-error>
          <mat-error
            *ngIf="forgotpasswordForm.get('email').hasError('pattern')"
          >
            {{ "Please enter a valid email" | translate }}
          </mat-error>
        </mat-form-field>
        <p class="msg" *ngIf="showSuccessMsg">
          {{
            "The reset password link has been sent to your registered mail."
              | translate
          }}
        </p>
        <p *ngIf="showErrorMsg" style="color: red; font-weight: bold">
          errorMsg
        </p>
        <div class="forgot-button-row">
          <button mat-raised-button [disabled]="loading || disableBtn">
            {{ "Continue" | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
