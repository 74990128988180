import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";

@Component({
  selector: "app-text-area",
  templateUrl: "./text-area.component.html",
  styleUrls: ["./text-area.component.scss"],
})
export class TextAreaComponent {
  @Input() controller: string;
  @Input() data: string;
  @Input() label: string = "Input Field";
  @Input() edit: boolean = true;
  @Input() valid: boolean = true;
  @Input() style: "normal" | "opaque" = "opaque";
  @Output() readonly change = new EventEmitter<string>();

  parentFormGroup: FormGroup;
  html: string = "";
  areaData: string = "";

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.parentFormGroup = this.controlContainer.control as FormGroup;
  }

  changeEvent(e: KeyboardEvent): void {
    this.change.emit((e?.target as HTMLInputElement)?.innerHTML);
  }
}
