import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "../core/credentials.service";
import { HttpErrorResponse } from "@angular/common/http";
import { finalize } from "rxjs/operators";
import { AuthenticationService } from "../core/authentication.service";
import { SharedService } from "../shared/shared.service";
import { TranslateService } from "@ngx-translate/core";
import passwordHash from "js-sha512";

@Component({
  selector: "app-login2-fa-type-code",
  templateUrl: "./login2-fa-type-code.component.html",
  styleUrls: ["./login2-fa-type-code.component.scss"],
})
export class Login2FaTypeCodeComponent implements OnInit {
  isLoading = false;
  blockButton = true;
  originalTimeTimer: number = 120;
  timeLeft: number = 120;
  timeLeftToRedirect: number = 7;
  interval;
  intervalToRedirect;

  //Second step
  subTitlePart1 = "Enter the 4-digit code sent to your email ";
  subTitlePart2 = " to log in.";
  allowResendOTPCode = false;
  deviceUseFrequencySelected = "Eventual use";
  deviceUseArray = ["Eventual use", "Frequent use"];
  //Errors
  displayRedError = false;
  errorMessage: string;

  //Warnings
  displayYellowError = false;
  warningMessage: string;

  otp: string = "";
  @ViewChild("ngOtpInput") ngOtpInputRef: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "-",
    inputStyles: {
      width: "56px",
      height: "56px",
    },
  };
  email: string;
  phoneNumber: string;
  resourseForOtp: string;
  methodSelected: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private translationService: TranslateService
  ) {
    this.startTimer();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.methodSelected = params["method"] ? params["method"] : "Email";
    });
    if (this.methodSelected === "Email") {
      this.subTitlePart1 = "Enter the 4-digit code sent to your email ";
      this.resourseForOtp = this.credentialsService.credentials.username;
    } else {
      this.subTitlePart1 = "Enter the 4-digit code sent to your phone number ";
      this.resourseForOtp = `+${this.credentialsService.credentials.countryCode}${this.credentialsService.credentials.phoneNumber}`;
    }
    this.email = this.credentialsService.credentials.username;
    this.phoneNumber = `${this.credentialsService.credentials.countryCode}${this.credentialsService.credentials.phoneNumber}`;
  }

  validateOTP() {
    this.isLoading = true;
    this.pauseTimer();
    const validate2FA$ = this.authenticationService.validate2FA(
      passwordHash.sha512(this.otp),
      this.deviceUseFrequencySelected === "Eventual use" ? 0 : 1
    );
    validate2FA$
      .pipe(
        finalize(() => {
          // this.loginForm.markAsPristine();
        })
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.router.navigate(
            [this.route.snapshot.queryParams.redirect || "/"],
            { replaceUrl: true }
          );
        },

        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.handleError(error);
        }
      );
  }

  resendOTPCode() {
    this.pauseTimer();
    this.isLoading = true;
    const resend2FA$ = this.authenticationService.resend2FA(
      this.email,
      this.methodSelected === "SMS"
    );
    resend2FA$
      .pipe(
        finalize(() => {
          // this.loginForm.markAsPristine();
        })
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.startTimer();
          // this.displayRedError = false;
          this.resetOTPInputValue();
        },

        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.handleError(error);
        }
      );
  }

  resetOTPInputValue() {
    this.ngOtpInputRef.setValue("");
  }

  handleError(error) {
    this.displayRedError = false;
    this.displayYellowError = false;
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error
      if (error.status === 404) {
        if (error.error.errors) {
          this.errorMessage = "Invalid code, validate and try again";
          this.displayRedError = true;
          // this.allowResendOTPCode = true;
          // this.resendOTPCode();
        } else {
          this.translationService
            .get(
              //this message is probably wrong...
              "The user entered is not registered. Please contact the HolaDOC team"
            )
            .subscribe((text: string) => {
              this.sharedService.showErrorMessage(text, "single");
            });
        }
      } else if (error.status == 400) {
        if (error.error.errors) {
          this.warningMessage = error.error.errors;
          this.displayYellowError = true;
          this.allowResendOTPCode = true;
          this.startCounterToRedirectToHome();
        } else {
          this.translationService
            .get(
              //this message is probably wrong...
              "The user entered is not registered. Please contact the HolaDOC team"
            )
            .subscribe((text: string) => {
              this.sharedService.showErrorMessage(text, "single");
            });
        }
      } else if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  //Timer functions
  startTimer() {
    this.timeLeft = this.originalTimeTimer;
    this.allowResendOTPCode = false;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        // this.timeLeft = this.originalTimeTimer;
        this.allowResendOTPCode = true;
        this.pauseTimer();
        this.blockButton = true;
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  onOtpChange(otp: string) {
    this.otp = otp;
    if (otp.length < 4) {
      this.blockButton = true;
    } else {
      this.blockButton = false;
    }
  }

  doBack() {
    history.back();
  }

  startCounterToRedirectToHome() {
    this.intervalToRedirect = setInterval(() => {
      if (this.timeLeftToRedirect > 0) {
        this.timeLeftToRedirect--;
      } else {
        clearInterval(this.intervalToRedirect);
        this.router.navigate(
          [this.route.snapshot.queryParams.redirect || "/login"],
          { replaceUrl: true }
        );
      }
    }, 1000);
  }
}
