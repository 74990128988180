import { Injectable } from "@angular/core";
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { LoaderService } from "./loader.service";
import { Observable } from "rxjs";

@Injectable()
export class LoaderInterceptorService {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.requests = this.requests.filter(
      (req) => !req.params.get("Hide-Loader")
    );

    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Skips loader for video call uploads. Doctors shouldn't wait until this operation has finished
    if (
      req.method.toLowerCase() === "patch" &&
      req.url.includes("/upload-videocall")
    ) {
      return next.handle(req);
    }

    const hideLoader = req.params.get("Hide-Loader");
    this.requests.push(req);

    if (hideLoader) {
      this.loaderService.isLoading.next(false);
    } else {
      this.loaderService.isLoading.next(true);
    }

    return Observable.create((observer) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
