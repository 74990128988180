<!-- <div class="example-boundary"> -->
<div class="example-box" cdkDragLockAxis="x" cdkDrag>
  <div id="audio-container">
    <div style="margin: auto">
      <div>{{ "Audio call" | translate }}</div>
      <div style="text-align: center">
        <p>{{ transform(time) }}</p>
      </div>
    </div>
    <div
      style="
        bottom: 10px;
        right: 25%;
        left: 50%;
        margin-left: -150px;
        text-align: center;
        z-index: 100;
        width: 100%;
        position: absolute;
      "
    >
      <img
        src="../../../assets/images/end.svg"
        (click)="disConnect()"
        class="action-btn"
      />
    </div>
  </div>
</div>
