<div class="background">
  <div class="left-bg">
    <img
      class="bottom-right-watermark"
      src="../../assets/images/holadoc-corazon.svg"
    />
    <div class="login-container">
      <div class="container">
        <mat-card class="login-card">
          <div class="close-button-container">
            <img
              src="../../assets/images/closeBlack.svg"
              class="close-button"
              (click)="doBack()"
            />
          </div>
          <div class="login-2fa-container">
            <img src="../../assets/images/2FAIcon.svg" class="logo-2fa" />
            <p class="title-2fa">{{ "Security code sent" | translate }}</p>
            <p class="subtitle-label">
              {{ subTitlePart1 | translate
              }}<span class="email-format">{{ resourseForOtp }}</span
              >{{ subTitlePart2 | translate }}
            </p>
            <ng-otp-input
              #ngOtpInput
              (onInputChange)="onOtpChange($event)"
              [config]="config"
            ></ng-otp-input>
            <p class="subtitle-label margin-vertical-10">
              {{ "Code expires in" | translate }}
              {{ timeLeft * 1000 | date: "mm:ss" }}
            </p>
            <button
              class="resend-button margin-vertical-10"
              [ngClass]="{ 'active-resend-button': allowResendOTPCode }"
              [disabled]="!allowResendOTPCode"
              (click)="resendOTPCode()"
            >
              {{ "Resend code" | translate }}
            </button>
            <div
              class="error-container errorAlert"
              [ngClass]="{ 'display-none': !displayRedError }"
            >
              <img src="../../assets/images/errorOutline.svg" />
              <p>{{ errorMessage | translate }}</p>
            </div>
            <div
              class="error-container warningAlert"
              [ngClass]="{ 'display-none': !displayYellowError }"
            >
              <img src="../../assets/images/warningAlertYellow.svg" />
              <p>{{ warningMessage | translate }}</p>
            </div>
            <p class="subtitle-label margin-vertical-10">
              {{
                "Do you use this device occasionally or frequently?" | translate
              }}
            </p>
            <mat-radio-group
              name="opciones"
              [(ngModel)]="deviceUseFrequencySelected"
            >
              <mat-radio-button
                *ngFor="let op of deviceUseArray"
                name="opciones"
                [value]="op"
                style="margin: 10px"
                color="primary"
                [labelPosition]="'after'"
              >
                {{ op | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="login-button-row bottom-padding">
              <button
                (click)="validateOTP()"
                [disabled]="isLoading || blockButton"
                mat-raised-button
              >
                {{ "Login" | translate }}
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
