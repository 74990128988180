import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AttachmentPopupComponent } from "../attachment-popup/attachment-popup.component";

@Component({
  selector: "app-view-attachment",
  templateUrl: "./view-attachment.component.html",
  styleUrls: ["./view-attachment.component.scss"],
})
export class ViewAttachmentComponent implements OnInit {
  attachPhotoOnCall: any;
  id;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewAttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  viewDocuments() {
    const dialogRef = this.dialog.open(AttachmentPopupComponent, {
      width: "402px",
      disableClose: true,
      data: { document: this.data.record._id, status: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialogRef.close();
    });
  }
}
